import { ReactNode } from 'react';
import { DropdownProvider } from '@sendible/design-system';

import { Localization } from './Localization';

const FrontendV2 = ({ children }: { children: ReactNode }) => (
  <Localization>
    <DropdownProvider>{children}</DropdownProvider>
  </Localization>
);

export default FrontendV2;
