import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { BackgroundUploaderContextType, PropsType, UpdateFileErrorParamsType, UpdateFileProgressParamsType } from './types';
import { UploadingFileStatus, UploadingFileType } from './UploadingFile/types';
import BackgroundUploader from './component';
import { AssetMediaType } from '../../models/assets/types';

export const BackgroundUploaderContext = createContext({} as BackgroundUploaderContextType);

export const BackgroundUploaderProvider: FC<PropsType> = ({ children }) => {
  const [uploadingFiles, setUploadingFiles] = useState<Record<string, UploadingFileType>>({});
  const [isMinimised, setIsMinimised] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const itemCountByStatus = useMemo(
    () =>
      Object.values(uploadingFiles).reduce(
        (acc, uploadingFile) => {
          const { status } = uploadingFile;

          return {
            ...acc,
            [status]: acc[status] + 1,
          };
        },
        { uploading: 0, ready: 0, errored: 0, aborted: 0 }
      ),
    [uploadingFiles]
  );

  const canBeClosed = useMemo(() => Object.values(uploadingFiles).every(({ status }) => status !== UploadingFileStatus.Uploading), [uploadingFiles]);
  const canCancelAll = useMemo(() => !!itemCountByStatus.uploading, [itemCountByStatus.uploading]);

  useEffect(() => {
    if (canBeClosed) {
      setIsMinimised(false);
    }
  }, [canBeClosed]);

  const closeUploader = () => {
    setIsClosed(true);
    setUploadingFiles({});
  };

  const addFile = (file: File, libraryName: string, abortController: AbortController) => {
    const fileId = Math.random().toString();
    let type: AssetMediaType | undefined;

    if (file.type.includes('image')) {
      type = AssetMediaType.Image;
    } else if (file.type.includes('video')) {
      type = AssetMediaType.Video;
    }

    setIsMinimised(false);
    setIsClosed(false);
    setUploadingFiles((prev) => {
      const newFile = {
        id: fileId,
        name: file.name,
        assetType: type,
        status: UploadingFileStatus.Uploading,
        libraryName,
        uploadProgress: 1,
        assetSize: file.size,
        abortController,
      };

      return { ...prev, [fileId]: newFile };
    });

    return fileId;
  };

  const updateFileProgress = ({ fileId, uploadProgress, abortController }: UpdateFileProgressParamsType) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            id: fileId,
            uploadProgress,
            abortUpload:
              abortController &&
              (() => {
                abortController.abort();
              }),
          },
        };
      }

      return { ...items };
    });
  };

  const updateFileError = ({ fileId, errorMessage }: UpdateFileErrorParamsType) => {
    setIsMinimised(false);
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            errorMessage,
            status: items[fileId].status !== UploadingFileStatus.Aborted ? UploadingFileStatus.Errored : UploadingFileStatus.Aborted,
          },
        };
      }

      return { ...items };
    });
  };

  const updateFileReady = (fileId: string) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            id: fileId,
            status: UploadingFileStatus.Ready,
          },
        };
      }

      return { ...items };
    });
  };
  const addAbortAPIToFile = (fileId: string, abortAPI: () => void) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            abortAPI,
          },
        };
      }

      return { ...items };
    });
  };
  const cancelAllUploads = () => {
    if (canCancelAll) {
      Object.values(uploadingFiles).forEach((uploadingFile) => {
        const { status, abortUpload, abortAPI, abortController } = uploadingFile;

        if (status !== UploadingFileStatus.Ready && status !== UploadingFileStatus.Aborted && status !== UploadingFileStatus.Errored) {
          abortController.abort();

          if (abortUpload) {
            abortUpload();
          }
          if (abortAPI) {
            abortAPI();
          }

          setUploadingFiles((items) => {
            if (items[uploadingFile.id]) {
              return {
                ...items,
                [uploadingFile.id]: { ...uploadingFile, status: UploadingFileStatus.Aborted },
              };
            }

            return { ...items };
          });
        }
      });
    }
  };

  const cancelUpload = (fileId: string) => {
    if (uploadingFiles[fileId]) {
      const { abortUpload, abortAPI, abortController } = uploadingFiles[fileId];

      abortController.abort();

      if (abortUpload && abortAPI) {
        abortUpload();
        abortAPI();
      }
      setUploadingFiles((items) => {
        if (items[fileId]) {
          return {
            ...items,
            [fileId]: {
              ...(items[fileId] || {}),
              status: UploadingFileStatus.Aborted,
            },
          };
        }

        return { ...items };
      });
    }
  };
  const canCancelUpload = (fileId: string) => !!uploadingFiles[fileId].abortController;

  return (
    <BackgroundUploaderContext.Provider
      value={{
        uploadingFiles,
        canCancelAll,
        addFile,
        updateFileProgress,
        updateFileError,
        updateFileReady,
        cancelAllUploads,
        cancelUpload,
        isMinimised,
        setIsMinimised,
        isClosed,
        itemCountByStatus,
        canBeClosed,
        canCancelUpload,
        addAbortAPIToFile,
        closeUploader,
      }}
    >
      {!isClosed && createPortal(<BackgroundUploader />, document.getElementById('root') as HTMLElement)}
      {children}
    </BackgroundUploaderContext.Provider>
  );
};

export const useBackgroundUploaderContext = (): BackgroundUploaderContextType => useContext(BackgroundUploaderContext);
