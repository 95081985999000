/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
import { createContext, useContext, useState } from 'react';
import { GlobalDropdown } from './component';
import { DropdownProps } from '../../molecules/DropdownOld';

type DropdownStateData = Partial<Omit<DropdownProps, 'isVisible' | 'close'>> & { ref?: any } & Component;

interface DropdownProviderData {
  dropdownState: DropdownStateData;
  isDropdownVisible: boolean;
  handleDropdown: (payload: DropdownStateData) => void;
  openDropdown: (payload: DropdownStateData) => void;
  closeDropdown: () => void;
}

const defaultDropdownState = {};

export const DropdownContext = createContext({} as DropdownProviderData);

export const DropdownProvider = ({ children }: DropdownStateData) => {
  const [dropdownState, setDropDownState] = useState<DropdownStateData>({});
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  const openDropdown = (payload: DropdownStateData) => {
    setDropDownState(payload);
    setIsDropdownVisible(true);
  };

  const closeDropdown = () => {
    setDropDownState(defaultDropdownState);
    setIsDropdownVisible(false);
  };

  const handleDropdown = (payload: DropdownStateData) => {
    isDropdownVisible && dropdownState.ref === payload.ref ? closeDropdown() : openDropdown(payload);
  };

  return (
    <DropdownContext.Provider
      value={{
        dropdownState,
        isDropdownVisible,
        handleDropdown,
        openDropdown,
        closeDropdown,
      }}
    >
      <GlobalDropdown />
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdownContext = (): DropdownProviderData => useContext(DropdownContext);
