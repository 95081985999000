import { MutationMethodEnum } from './types';

export default {
  // Queries
  GetMediaLibraries: {
    endpoint: '/api/v3/workspaces/media/libraries',
    interval: 600000, // 10 minutes
  },
  GetAssets: {
    endpoint: '/api/v3/workspaces/media/libraries/assets',
    interval: 300000, // 5 minutes
  },
  // Mutations
  CreateMediaLibrary: {
    endpoint: '/api/v3/workspaces/media/library',
    method: MutationMethodEnum.post,
  },
  RenameMediaLibrary: {
    endpoint: '/api/v3/workspaces/media/library',
    method: MutationMethodEnum.put,
  },
  DeleteMediaLibrary: {
    endpoint: '/api/v3/workspaces/media/library',
    method: MutationMethodEnum.delete,
  },
  UploadAssets: {
    endpoint: '/api/v3/workspaces/media/libraries/assets',
    method: MutationMethodEnum.post,
  },
  DeleteAsset: {
    endpoint: '/api/v3/workspaces/media/libraries/asset',
    method: MutationMethodEnum.delete,
  },
  RenameAsset: {
    endpoint: '/api/v3/workspaces/media/libraries/asset',
    method: MutationMethodEnum.put,
  },
  CompleteUploadAssets: {
    endpoint: '/api/v3/workspaces/media/assets/complete_upload',
    method: MutationMethodEnum.post,
  },
  AbortUploadAssets: {
    endpoint: '/api/v3/workspaces/media/assets/abort_upload',
    method: MutationMethodEnum.post,
  },
} as const;
