import styled, { css } from 'styled-components';
import { CheckboxProps } from '.';

interface CheckProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  hasError?: boolean;
}

type ContainerProps = Omit<CheckboxProps, 'label'> & Pick<CheckProps, 'isIndeterminate' | 'isDisabled'>;

export const Container = styled.label<ContainerProps>`
  ${({ theme: { colors, fontSizes }, isDisabled, isIndeterminate, size }) => css`
    align-items: flex-start;
    color: ${colors.brandDark};
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    display: flex;
    gap: 0.5em;
    user-select: none;

    * {
      cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
      font-size: inherit;
    }

    /* ${isIndeterminate &&
    css`
      pointer-events: none;

      * {
        pointer-events: none;
      }
    `} */

    ${size === 'sm' &&
    css`
      font-size: ${fontSizes.s12};
    `}

    ${size === 'md' &&
    css`
      font-size: ${fontSizes.s16};
    `}

      ${size === 'lg' &&
    css`
      font-size: ${fontSizes.s20};
    `}

      ${size === 'xl' &&
    css`
      font-size: ${fontSizes.s24};
    `}
  `}
`;

export const Check = styled.span<CheckProps>`
  ${({ theme: { borderRadius, colors, motionSpeeds, motions }, hasError, isChecked, isDisabled, isIndeterminate }) => css`
    align-items: center;
    border-radius: ${borderRadius.sm};
    display: flex;
    flex-shrink: 0;
    height: 2em;
    justify-content: center;
    position: relative;
    width: 2em;

    .icon {
      height: 1em;
      left: 50%;
      position: absolute;
      top: 50%;
      transition: all ${motionSpeeds.fast} ${motions.default};
      transform: translate(-50%, -50%);
      width: 1em;
      z-index: 1;

      path {
        stroke: ${isChecked ? colors.brandLight : 'transparent'};

        ${isChecked &&
        css`
          stroke: ${colors.brandLight};
        `}

        ${!isChecked &&
        css`
          stroke: transparent;
        `}

        ${isIndeterminate &&
        css`
          stroke: ${colors.brandLight};
        `}

        ${isDisabled &&
        css`
          stroke: ${colors.brandDarkAlpha10};
        `}
      }
    }

    input {
      appearance: none;
      background: ${isChecked ? colors.brandPrimary : colors.brandLight};
      border-radius: ${borderRadius.sm};
      height: 1.5em;
      margin: 0;
      outline-offset: 0px;
      outline: 3px solid transparent;
      transition: all ${motionSpeeds.fast} ${motions.default};
      width: 1.5em;

      ${isChecked &&
      css`
        background: ${colors.brandPrimary};

        ${hasError &&
        css`
          background: ${colors.brandPrimary};
        `}
      `}

      ${!isChecked &&
      css`
        background: ${colors.brandLight};
        box-shadow: inset 0 0 0 1px ${colors.brandDarkAlpha20};

        &:hover {
          box-shadow: inset 0 0 0 1px ${colors.brandPrimary};
        }

        ${hasError &&
        css`
          background: ${colors.brandLight};
          box-shadow: inset 0 0 0 1px ${colors.semanticDanger};
        `}
      `}

      ${isIndeterminate &&
      css`
        background: ${colors.brandPrimary};
      `}

      &:focus-visible,
      &:focus {
        box-shadow: inset 0 0 0 1px ${colors.brandPrimary};
        outline-color: ${colors.brandPrimaryAlpha20};
      }

      &:disabled {
        background: ${colors.brandDarkAlpha5};
        box-shadow: none;
      }
    }
  `}
`;

export const Text = styled.span`
  display: block;
  line-height: 1.5em;
  margin: 0.25em 0;
`;
