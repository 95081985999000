import { fetchWithHeaders, urls } from '@sendible/common';
import { MutationFunction, QueryFunction, QueryFunctionContext, useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import {
  APIResponseType,
  QueryKey,
  UseQueryOptionsWithGenerics,
  UseInfiniteQueryOptionsWithGenerics,
  InfiniteQueryKey,
  UseMutationWithAccessTokenParamsType,
} from './types';
import { assetsLoadedInfiniteScroll } from '../pages/MediaLibrary/pendoEvents';

export function useQueryWithAccessToken<TData>([endpoint, params]: QueryKey, options?: UseQueryOptionsWithGenerics<TData>) {
  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const newQueryKey: QueryKey = [endpoint, { access_token: accessToken, ...(params as object) }];
  const queryFn: QueryFunction<APIResponseType, QueryKey> = ({ queryKey }: QueryFunctionContext<QueryKey>) => {
    const [endpnt, prms] = queryKey;

    return fetchWithHeaders({ method: 'GET', url: `${urls.baseUrl}${endpnt}`, params: prms });
  };

  return useQuery(newQueryKey, queryFn, { useErrorBoundary: true, ...options });
}
export function useInfiniteQueryWithAccessToken<TData>([endpoint, params]: InfiniteQueryKey, options?: UseInfiniteQueryOptionsWithGenerics<TData>) {
  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const newQueryKey: InfiniteQueryKey = [endpoint, { access_token: accessToken, ...params }];
  const queryFn: QueryFunction<APIResponseType, QueryKey> = ({ queryKey, pageParam = 1 }) => {
    const [endpnt, prms] = queryKey;

    const newParams = {
      ...prms,
      page: pageParam,
    };

    window.pendo.track(assetsLoadedInfiniteScroll, {
      perPage: params.perPage,
      page: pageParam,
    });

    return fetchWithHeaders({ method: 'GET', url: `${urls.baseUrl}${endpnt}`, params: newParams });
  };

  return useInfiniteQuery(newQueryKey, queryFn, {
    ...options,
    useErrorBoundary: true,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.data && lastPage.data.length < params.perPage ? undefined : allPages.length + 1;
    },
  });
}

export function useMutationWithAccessToken<TData, TVariables>({
  method,
  endpoint,
  options,
  mutationFn,
}: UseMutationWithAccessTokenParamsType<TData, TVariables>) {
  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const defaultMutationFn: MutationFunction<TData, object> = (variables: object) => {
    const newContent = { access_token: accessToken, ...variables };

    if (mutationFn) {
      return mutationFn(newContent as TVariables);
    }

    return fetchWithHeaders({ method, url: `${urls.baseUrl}${endpoint}`, params: newContent });
  };

  return useMutation(defaultMutationFn, { ...options });
}
