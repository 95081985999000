import { AssetMediaType } from '@sendible/frontendv2/src/models/assets/types';

export enum UploadingFileStatus {
  Aborted = 'aborted',
  Ready = 'ready',
  Uploading = 'uploading',
  Errored = 'errored',
}
export interface UploadingFileProps {
  id: string;
  errorMessage?: string;
  uploadProgress?: number;
  libraryName?: string;
  name: string;
  assetType?: AssetMediaType;
  status: UploadingFileStatus;
  abortAPI?: () => void;
  abortController: AbortController;
}

export interface UploadingFileType extends UploadingFileProps {
  abortUpload?: () => void;
  assetSize: number;
}

export interface UploadingFileProgressBarProps {
  uploadProgress?: number;
  status: UploadingFileStatus;
}
