import { MouseEvent, ReactNode, useRef } from 'react';
import { Avatar, Container, Content } from './index.styles';
import { Button } from '../Button';
import { Dropdown, DropdownOption } from '../Dropdown';
import { Icon } from '../../atoms/Icon';
import { IconNames } from '../../../theme';

export interface SidebarNavItemProps extends Component {
  /**
   * Aria options label. Optional.
   */
  ariaItemLabel?: string;
  /**
   * Link avatar url. Optional.
   */
  avatar?: string;
  /**
   * Custom Avatar component. Optional
   */
  CustomAvatar?: ReactNode;
  /**
   * click event emitter. Optional.
   */
  click?: (e: MouseEvent) => void;
  /**
   * Dropdown button event emitter. Required.
   */
  dropdownButtonClick?: (option: string, index: number) => void;
  /**
   * Drop down options.Optional.
   */
  dropDownOptions?: DropdownOption[];
  /**
   * HTML id attr. Required.
   */
  id: string;
  /**
   * Control active state. Optional.
   */
  isActive?: boolean;
  /**
   * Link label. Required.
   */
  label: string;
  /**
   * Icon
   */
  icon?: IconNames;
}

/**
 * SidebarNavItem
 */
export const SidebarNavItem = (props: SidebarNavItemProps) => {
  const {
    ariaItemLabel = '',
    avatar,
    icon,
    click,
    className = '',
    customClass = '',
    id,
    isActive = false,
    dropdownButtonClick,
    dropDownOptions,
    label,
    CustomAvatar,
  } = props;

  const ref = useRef(null);

  return (
    <Container
      className={`sidebar-nav-item ${customClass} ${className}`}
      title={label}
      id={id}
      isActive={isActive}
      isDropdownVisible={isActive}
      data-testid="sidebar-element"
    >
      <Content
        hasOptions={!!dropDownOptions}
        isActive={isActive}
        onClick={(e: MouseEvent) => click && click(e)}
        data-testid={`sidebar-element-${label}`}
        aria-label={ariaItemLabel}
      >
        {avatar && (
          <Avatar>
            <img
              src={avatar}
              alt={label}
            />
          </Avatar>
        )}
        {CustomAvatar}
        {icon && (
          <Icon
            className="icon"
            name={icon}
          />
        )}
        <span className="label-item">{label}</span>
      </Content>

      {dropDownOptions && dropdownButtonClick && (
        <Dropdown
          options={dropDownOptions}
          select={dropdownButtonClick}
        >
          <Button
            icon="more_horizontal"
            title="options"
            size={14}
            ref={ref}
            customClass="sidebar-nav-item-button"
          />
        </Dropdown>
      )}
    </Container>
  );
};
