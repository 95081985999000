import styled, { css } from 'styled-components';
import { SecondarySidebar } from '@sendible/design-system';

export const SecondarySidebarStyled = styled(SecondarySidebar)`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    .sd-modal {
      left: unset;
      right: 0;
      z-index: 101;
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      z-index: 11;
    }
  `}
`;
