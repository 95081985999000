import { Fragment, useEffect, useRef, useState } from 'react';
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/react';
import { Icon, Input, helpers } from '@sendible/design-system';
import { useInView } from 'react-intersection-observer';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Container, Display, InputArea, List, ListItem } from './index.styles';

export interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  onEnter?: (option: string) => void;
  onSelect: (option: string) => void;
  selectedOption?: string;
  testid?: string;
  title: string;
  showInput?: boolean;
}

export const SelectWithInput = ({ onEnter, onSelect, options, selectedOption, testid, title, showInput = false }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const blurChecker = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('ai_assist');

  const errorFeedback = t('audience_input_error');

  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    rootMargin: '-8px',
  });

  const { x, y, strategy, refs } = useFloating({
    open: isCollapsed,
    onOpenChange: setIsCollapsed,
    placement: 'bottom-start',
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const handleKeyDown = (key: string, index: number, listLength: number) => {
    if (key === 'ArrowUp' && index > 0) {
      document.activeElement.previousSibling.focus();
    }
    if (key === 'ArrowDown' && index < listLength - 1) {
      document.activeElement.nextSibling.focus();
    }
  };

  const handleEnter = (value: string) => {
    const replacedValue = value.replace(/\s\s+/g, ' ').trim();

    if (replacedValue.length < 3) {
      setHasError(true);
    } else if (onEnter) {
      onEnter(replacedValue);
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (blurChecker.current && target.id !== 's-w-f') {
        setIsCollapsed(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [blurChecker]);

  useEffect(() => {
    if (isCollapsed && !inView) {
      setIsCollapsed(false);
    }
  }, [inView]);

  useEffect(() => {
    if (isCollapsed && inputRef.current) {
      setHasError(false);
      inputRef.current.focus();
    }
  }, [isCollapsed]);

  const optionList = options.map((option, index) => (
    <Fragment key={helpers.generateId()}>
      <ListItem
        id="s-w-f"
        onClick={() => {
          onSelect(option.value);
          setIsCollapsed(false);
        }}
        onKeyDown={(e) => handleKeyDown(e.key, index, optionList.length)}
        role="menuitem"
        tabIndex={index}
        title={option.label}
        $selected={option.label === title}
      >
        {option.label}
      </ListItem>
    </Fragment>
  ));

  return (
    <Container data-testid={testid}>
      <Display
        $isCollapsed={isCollapsed}
        ref={refs.setReference}
        onClick={() => setIsCollapsed((prev) => !prev)}
      >
        <span>{selectedOption || title}</span>
        <Icon
          name="chevron_down"
          color="brandDark"
        />
      </Display>
      {isCollapsed &&
        createPortal(
          <List
            id="s-w-f"
            ref={refs.setFloating}
            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
            tabIndex={-1}
          >
            <div
              className="dropdown-contentarea"
              id="s-w-f"
              ref={blurChecker}
              tabIndex={-1}
            >
              {showInput && (
                <InputArea $hasError={hasError}>
                  <Input
                    label={t('audience_input')}
                    id="s-w-f"
                    keyPress={(key, value) => key === 'Enter' && onEnter && handleEnter(value)}
                    maxLength={50}
                    ref={inputRef}
                    size="sm"
                    feedback={hasError ? { message: errorFeedback, type: 'danger' } : undefined}
                    feedbackDuration={2500}
                    onFeedbackDurationEnd={() => setHasError(false)}
                  />
                </InputArea>
              )}
              <ul
                aria-labelledby={`swf-${title}`}
                id="s-w-f"
                role="menu"
                tabIndex={-1}
                ref={inViewRef}
              >
                {optionList}
              </ul>
            </div>
          </List>,
          document.getElementById('dropdown-root') as HTMLElement
        )}
    </Container>
  );
};
