import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { MOBILE_MAX_SIZE, useWindowSize, GlobalDropdown } from '@sendible/design-system';
import { ContentSection, Sidebar } from './components';
import MediaLibraryContext from './MediaLibraryContext';
import { defaultMediaLibrary, mediaLibraryIdParam, assetSearchParam, assetsPerPageMobile, assetsPerPageDesktop } from './constants';
import ErrorBoundary from './errorBoundaries/errorBoundary';
import { ContainerStyled } from './index.styles';
import SelectedAssetsContext from './SelectedAssetsContext';

export const MediaLibrary: FC = () => {
  const [activeMediaLibrary, setActiveMediaLibrary] = useState(defaultMediaLibrary);
  const [sidebarIsVisible, setSidebarIsVisible] = useState(false);
  const [assetSearch, setAssetSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(width <= MOBILE_MAX_SIZE);
  const [perPage, setPerPage] = useState(isMobile ? assetsPerPageMobile : assetsPerPageDesktop);
  const [selectedAssetsIds, setSelectedAssetsIds] = useState<string[]>([]);

  const [
    {
      user: { isWhiteLabel },
    },
  ] = useBridgeContext();

  useEffect(() => {
    setIsMobile(width <= MOBILE_MAX_SIZE);
  }, [width]);

  useEffect(() => {
    setPerPage(isMobile ? assetsPerPageMobile : assetsPerPageDesktop);
  }, [isMobile]);

  useEffect(() => {
    const setMediaLibraryIdParam = (mediaLibraryId: string) => {
      setSearchParams({ ...Object.fromEntries(searchParams), [mediaLibraryIdParam]: mediaLibraryId });
    };

    if (activeMediaLibrary?.id) {
      setMediaLibraryIdParam(activeMediaLibrary.id);
    }
    setSelectedAssetsIds([]);
  }, [activeMediaLibrary.id, activeMediaLibrary.workspaceId]);

  useEffect(() => {
    setSearchParams({ ...Object.fromEntries(searchParams), [assetSearchParam]: assetSearch });
  }, [assetSearch]);

  useEffect(() => {
    const currentAssetSearch = searchParams.get(assetSearchParam);

    if (currentAssetSearch) {
      setAssetSearch(currentAssetSearch);
    }
  }, []);

  return (
    <MediaLibraryContext.Provider
      value={{
        activeMediaLibrary,
        setActiveMediaLibrary,
        sidebarIsVisible,
        setSidebarIsVisible,
        assetSearch,
        setAssetSearch,
        perPage,
        setPerPage,
        isMobile,
        setIsMobile,
      }}
    >
      <SelectedAssetsContext.Provider
        value={{
          selectedAssetsIds,
          setSelectedAssetsIds,
        }}
      >
        <ContainerStyled className="isV2Page">
          <ErrorBoundary
            isWhiteLabel={isWhiteLabel}
            errorSection="mediaLibrarySection"
          >
            <GlobalDropdown />
            <Sidebar />
            <ErrorBoundary
              isWhiteLabel={isWhiteLabel}
              errorSection="contentSection"
            >
              <ContentSection />
            </ErrorBoundary>
          </ErrorBoundary>
        </ContainerStyled>
      </SelectedAssetsContext.Provider>
    </MediaLibraryContext.Provider>
  );
};

export default MediaLibrary;
