import { fetchWithHeaders, urls, fileUtils } from '@sendible/common';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useContext, useState } from 'react';
import { EVENTS, publish } from '@sendible/event-manager';
import endpoints from '../../../../data-layer/endpoints';
import mediaLibraryContext from '../../MediaLibraryContext';
import { AssetType } from '../../../../models/assets/types';

const attachMediaToCompose = (publicMediaAssetData: [AssetType]) => {
  if (publicMediaAssetData[0].objectType === 'video') {
    const videoObject = {
      nameSanitised: fileUtils.removeFileNameExtension(publicMediaAssetData[0].name),
      ...publicMediaAssetData[0],
      thumbnail: publicMediaAssetData[0].thumbnail,
      metadata: {
        ...publicMediaAssetData[0].metadata,
        size: Math.ceil(publicMediaAssetData[0].metadata.size / 1024),
      },
    };

    publish(EVENTS.OPEN_COMPOSE, {
      assetType: 'video',
      data: videoObject,
    });
  } else {
    const newData = publicMediaAssetData.map((asset) => {
      const { name, url } = asset;

      return {
        name,
        url,
      };
    });

    publish(EVENTS.OPEN_COMPOSE, {
      assetType: 'image',
      data: newData,
    });
  }
};

const usePostToCompose = (initialMediaIds: Array<string> | null, requestClose: () => void) => {
  const [isMediaRequestPending, setIsMediaRequestPending] = useState(false);
  const [mediaIds, setMediaIds] = useState(initialMediaIds);
  const { activeMediaLibrary } = useContext(mediaLibraryContext);
  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const postToCompose = async () => {
    setIsMediaRequestPending(true);

    (document.activeElement as HTMLElement).blur();

    try {
      const { data } = await fetchWithHeaders({
        method: 'GET',
        url: `${urls.baseUrl}${endpoints.GetAssets.endpoint}`,
        params: {
          access_token: accessToken,
          mediaLibraryId: activeMediaLibrary?.id,
          'filter[ids]': mediaIds,
          public: true,
        },
      });

      attachMediaToCompose(data);
      (document.activeElement as HTMLElement).blur();
    } catch {
      // showToast('There was an issue processing your image attachment, please try again.', { type: NotificationTypeEnum.Error });
    }

    requestClose();
  };

  return {
    postToCompose,
    setMediaIds,
    isMediaRequestPending,
  };
};

export default usePostToCompose;
