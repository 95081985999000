export enum AssetMediaType {
  Image = 'image',
  Video = 'video',
}

export enum AssetStatus {
  Aborted = 'aborted',
  Deleted = 'deleted',
  Ready = 'ready',
  Uploading = 'uploading',
}

export type AssetActions = 'delete';

export interface UserConnectionType {
  id: number;
  name: string;
}

export interface CreatorType {
  createdAt: number;
  createdBy: UserConnectionType;
}

export interface UpdaterType {
  updatedAt: number;
  updatedBy: UserConnectionType;
}

export type AssetLifecycleType = CreatorType & UpdaterType;

export interface MetadataType {
  mediaType: string;
  size: number;
}

export type ImageMetadataType = MetadataType;
export type VideoMetadataType = MetadataType;
export type ValidAndInvalidFileLists = {
  validFiles: File[];
  invalidFiles: File[];
};
export interface AssetMetadataType {
  metadata: ImageMetadataType | VideoMetadataType;
}
export interface BasicAssetType {
  id: string;
  name: string;
  objectType: AssetMediaType;
  status?: AssetStatus;
}

export interface AssetUrlType {
  thumbnail: string;
  url: string;
  downloadUrl: string;
}

export type AssetType = BasicAssetType & AssetLifecycleType & AssetMetadataType & AssetUrlType;

export type AssetsAPIResponseType = {
  data: AssetType[];
  totalCount: number;
};

export interface AssetMutationParamsType {
  id: string;
  name: string;
}

export interface AssetUploadParamsType {
  file: File;
}
