/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useState, useEffect, useRef, forwardRef, UIEvent, KeyboardEvent } from 'react';
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/react';
import { useInView } from 'react-intersection-observer';
import { Backdrop, Header, List, MobileWrapper, Option, Spacer } from './index.styles';
import { Button } from '../Button';
import { generateId } from '../../utils/helpers';
import { MOBILE_MAX_SIZE } from '../../utils/constants';
import { useWindowSize } from '../../utils/useWindowSize';
import { Positions } from '../../../theme';

export interface OptionProps {
  action: string;
  click?: () => void;
  isHighlighted?: boolean;
  label: string;
}

export interface DropdownProps extends Omit<Component, 'children'> {
  /**
   * Close event emmiter. Optional.
   */
  close: () => void;
  /**
   * Dropdown opening position. Optional.
   */
  defaultPosition?: Positions;
  /**
   * Use this to ignore list default width and adapt to inner content. Optional.
   */
  fitContent?: boolean;
  /**
   * Toggles this component visibility. Required.
   */
  isVisible: boolean;
  /**
   * List of options. Required.
   */
  options: OptionProps[];
  /**
   * Option select event emitter. Required.
   */
  select: (option: string, action: string, index: number) => void;
  /**
   * Custom z-index value. Optional.
   */
  zIndex?: number;
}

/**
 * Dropdown Old
 */
export const DropdownOld = forwardRef((props: DropdownProps, ref?: any) => {
  const {
    className = '',
    close,
    customClass = '',
    defaultPosition = 'bottom-start',
    fitContent,
    isVisible,
    options,
    select,
    testId = 'dropdown',
    zIndex = 1,
  } = props;

  const [scrolled, setScrolled] = useState(false);
  const { width } = useWindowSize();
  const blurChecker = useRef(null);
  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    rootMargin: '-8px',
  });

  const onScroll = (e: UIEvent) => {
    const target = e.target as HTMLElement;

    setScrolled(target.scrollTop > 0);
  };

  const { x, y, strategy, refs } = useFloating({
    open: isVisible,
    onOpenChange: close,
    placement: defaultPosition,
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const handleKeyDown = (key: string, index: number, listLength: number) => {
    if (key === 'ArrowUp' && index > 0) {
      const { previousSibling } = document.activeElement;

      if (previousSibling.role === 'presentation') {
        previousSibling.previousSibling.focus();
      } else {
        previousSibling.focus();
      }
    }
    if (key === 'ArrowDown' && index < listLength - 1) {
      const { nextSibling } = document.activeElement;

      if (nextSibling.role === 'presentation') {
        nextSibling.nextSibling.focus();
      } else {
        nextSibling.focus();
      }
    }
    if (key === 'Escape') {
      ref.focus();
      close();
    }
  };

  useEffect(() => {
    if (ref) refs.setReference(ref);
  }, [ref]);

  useEffect(() => {
    if (isVisible && !inView) {
      close();
    }
  }, [inView]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (blurChecker.current && width > MOBILE_MAX_SIZE && target.id !== 'm-e-b' && target.id !== ref.id) {
        close();
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [blurChecker, width, ref]);

  const optionList = options.map((option, index) => (
    <Fragment key={generateId()}>
      {option.isHighlighted && (
        <Spacer
          role="presentation"
          tabIndex={-1}
        />
      )}
      <Option
        autoFocus={index === 0}
        id="m-e-b"
        data-testid={`${testId}-option-${option.action}`}
        onClick={() => {
          select(option.label, option.action, index);
          option.click && option.click();
        }}
        onKeyDown={(e: KeyboardEvent<HTMLElement>) => handleKeyDown(e.key, index, optionList.length)}
        role="menuitem"
        tabIndex={index}
        title={option.label}
      >
        {option.label}
      </Option>
    </Fragment>
  ));

  return (
    <>
      {width > MOBILE_MAX_SIZE ? (
        <>
          {isVisible && (
            <List
              className={`ds-dropdown-list ${customClass}-list ${className}-list`}
              data-testid={testId}
              $fitContent={fitContent}
              id="m-e-b"
              $isVisible={isVisible}
              // onBlur={close}
              ref={refs.setFloating}
              style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
              tabIndex={-1}
              $zIndex={zIndex}
            >
              <div
                className="dropdown-contentarea"
                id="m-e-b"
                ref={blurChecker}
                tabIndex={-1}
              >
                <ul
                  aria-labelledby={ref?.id}
                  id="m-e-b"
                  role="menu"
                  tabIndex={-1}
                  ref={inViewRef}
                >
                  {optionList}
                </ul>
              </div>
            </List>
          )}
        </>
      ) : (
        <>
          {isVisible && (
            <>
              <MobileWrapper $isVisible={isVisible}>
                <Header showLine={scrolled}>
                  <Button
                    onClick={close}
                    icon="close"
                    size={16}
                  />
                </Header>
                <List
                  className={`ds-dropdown-list ${customClass}-list ${className}-list`}
                  $isVisible={isVisible}
                  $zIndex={zIndex}
                >
                  <div className="dropdown-contentarea">
                    <ul
                      aria-labelledby={ref?.id}
                      onScroll={onScroll}
                      role="menu"
                    >
                      {optionList}
                    </ul>
                  </div>
                </List>
              </MobileWrapper>
              <Backdrop
                onClick={close}
                data-testid="backdrop"
              />
            </>
          )}
        </>
      )}
    </>
  );
});
