import { tint, shade, rgba } from 'polished';
import { DefaultTheme } from 'styled-components';
import { whitelabelColors } from './whitelabelColors';
import {
  accentColors,
  borderRadius,
  brandColors,
  dangerColors,
  darkColors,
  fontSizes,
  fontWeights,
  iconNames,
  infoColors,
  lightColors,
  motions,
  motionSpeeds,
  positions,
  primaryColors,
  secondaryColors,
  semanticColors,
  spacings,
  successColors,
  warningColors,
} from './typeConstants';

export const themeGenerator = (customs: ThemeColors = whitelabelColors.default): DefaultTheme => {
  const { colorPrimary, colorSecondary, colorAccent } = customs;
  const theme = {
    borderRadius: {
      sm: '0.25rem',
    },
    breakPoints: {
      smallDevices: {
        min: 'min-width: 0px',
        max: 'max-width: 799px',
      },
      mediumDevices: {
        min: 'min-width: 800px',
        max: 'max-width: 1024px',
      },
      laptop: {
        min: 'min-width: 1025px',
        max: 'max-width: 1440px',
      },
      desktop: {
        min: 'min-width: 1441px',
        max: 'max-width: 1920px',
      },
      qhd: {
        min: 'min-width: 1921px',
        max: 'max-width: 2560px',
      },
      uhd: {
        min: 'min-width: 2561px',
      },
    },
    colors: {
      brandPrimary: colorPrimary,
      brandPrimaryShade10: shade(0.1, colorPrimary),
      brandPrimaryShade20: shade(0.2, colorPrimary),
      brandPrimaryShade30: shade(0.3, colorPrimary),
      brandPrimaryShade40: shade(0.4, colorPrimary),
      brandPrimaryShade50: shade(0.5, colorPrimary),
      brandPrimaryTint10: tint(0.1, colorPrimary),
      brandPrimaryTint20: tint(0.2, colorPrimary),
      brandPrimaryTint30: tint(0.3, colorPrimary),
      brandPrimaryTint40: tint(0.4, colorPrimary),
      brandPrimaryTint50: tint(0.5, colorPrimary),
      brandPrimaryTint60: tint(0.6, colorPrimary),
      brandPrimaryTint70: tint(0.7, colorPrimary),
      brandPrimaryTint80: tint(0.8, colorPrimary),
      brandPrimaryTint90: tint(0.9, colorPrimary),
      brandPrimaryAlpha90: rgba(colorPrimary, 0.9),
      brandPrimaryAlpha80: rgba(colorPrimary, 0.8),
      brandPrimaryAlpha70: rgba(colorPrimary, 0.7),
      brandPrimaryAlpha60: rgba(colorPrimary, 0.6),
      brandPrimaryAlpha50: rgba(colorPrimary, 0.5),
      brandPrimaryAlpha40: rgba(colorPrimary, 0.4),
      brandPrimaryAlpha30: rgba(colorPrimary, 0.3),
      brandPrimaryAlpha20: rgba(colorPrimary, 0.2),
      brandPrimaryAlpha10: rgba(colorPrimary, 0.1),
      brandPrimaryAlpha5: rgba(colorPrimary, 0.05),
      brandSecondary: colorSecondary,
      brandSecondaryTint10: tint(0.1, colorSecondary),
      brandSecondaryTint20: tint(0.2, colorSecondary),
      brandSecondaryTint30: tint(0.3, colorSecondary),
      brandSecondaryTint40: tint(0.4, colorSecondary),
      brandSecondaryTint50: tint(0.5, colorSecondary),
      brandSecondaryTint60: tint(0.6, colorSecondary),
      brandSecondaryTint70: tint(0.7, colorSecondary),
      brandSecondaryTint80: tint(0.8, colorSecondary),
      brandSecondaryTint90: tint(0.9, colorSecondary),
      brandAccent: colorAccent,
      brandAccentShade10: shade(0.1, colorAccent),
      brandAccentShade20: shade(0.2, colorAccent),
      brandAccentShade30: shade(0.3, colorAccent),
      brandAccentShade40: shade(0.4, colorAccent),
      brandAccentShade50: shade(0.5, colorAccent),
      brandAccentTint10: tint(0.1, colorAccent),
      brandAccentTint20: tint(0.2, colorAccent),
      brandAccentTint30: tint(0.3, colorAccent),
      brandAccentTint40: tint(0.4, colorAccent),
      brandAccentTint50: tint(0.5, colorAccent),
      brandAccentTint60: tint(0.6, colorAccent),
      brandAccentTint70: tint(0.7, colorAccent),
      brandAccentTint80: tint(0.8, colorAccent),
      brandAccentTint90: tint(0.9, colorAccent),
      brandAccentAlpha90: rgba(colorAccent, 0.9),
      brandAccentAlpha80: rgba(colorAccent, 0.8),
      brandAccentAlpha70: rgba(colorAccent, 0.7),
      brandAccentAlpha60: rgba(colorAccent, 0.6),
      brandAccentAlpha50: rgba(colorAccent, 0.5),
      brandAccentAlpha40: rgba(colorAccent, 0.4),
      brandAccentAlpha30: rgba(colorAccent, 0.3),
      brandAccentAlpha20: rgba(colorAccent, 0.2),
      brandAccentAlpha10: rgba(colorAccent, 0.1),
      brandAccentAlpha5: rgba(colorAccent, 0.05),
      brandDark: '#201e24',
      brandDarkTint10: tint(0.1, '#201e24'),
      brandDarkTint20: tint(0.2, '#201e24'),
      brandDarkTint30: tint(0.3, '#201e24'),
      brandDarkTint40: tint(0.4, '#201e24'),
      brandDarkTint50: tint(0.5, '#201e24'),
      brandDarkTint60: tint(0.6, '#201e24'),
      brandDarkTint70: tint(0.7, '#201e24'),
      brandDarkTint80: tint(0.8, '#201e24'),
      brandDarkTint90: tint(0.9, '#201e24'),
      brandDarkTint95: tint(0.95, '#201e24'),
      brandDarkAlpha90: rgba('#201e24', 0.9),
      brandDarkAlpha80: rgba('#201e24', 0.8),
      brandDarkAlpha70: rgba('#201e24', 0.7),
      brandDarkAlpha60: rgba('#201e24', 0.6),
      brandDarkAlpha50: rgba('#201e24', 0.5),
      brandDarkAlpha40: rgba('#201e24', 0.4),
      brandDarkAlpha30: rgba('#201e24', 0.3),
      brandDarkAlpha20: rgba('#201e24', 0.2),
      brandDarkAlpha15: rgba('#201e24', 0.15),
      brandDarkAlpha10: rgba('#201e24', 0.1),
      brandDarkAlpha5: rgba('#201e24', 0.05),
      brandLight: '#ffffff',
      brandLightTint90: tint(0.9, '#ffffff'),
      brandLightAlpha10: rgba('#ffffff', 0.1),
      brandLightAlpha40: rgba('#ffffff', 0.4),
      brandLightAlpha50: rgba('#ffffff', 0.5),
      brandLightAlpha60: rgba('#ffffff', 0.6),
      brandLightAlpha70: rgba('#ffffff', 0.7),
      brandLightAlpha90: rgba('#ffffff', 0.9),
      brandLightAlpha95: rgba('#ffffff', 0.95),
      semanticInfo: '#0072e8',
      semanticInfoShade10: shade(0.1, '#0072e8'),
      semanticInfoShade50: shade(0.5, '#0072e8'),
      semanticInfoTint80: tint(0.8, '#0072e8'),
      semanticInfoAlpha20: rgba('#0072e8', 0.2),
      semanticInfoAlpha5: rgba('#0072e8', 0.05),
      semanticSuccess: '#00d686',
      semanticSuccessShade10: shade(0.1, '#00d686'),
      semanticSuccessShade50: shade(0.5, '#00d686'),
      semanticSuccessTint80: tint(0.8, '#00d686'),
      semanticSuccessTint90: tint(0.9, '#00d686'),
      semanticSuccessAlpha20: rgba('#00d686', 0.2),
      semanticSuccessAlpha5: rgba('#00d686', 0.05),
      semanticWarning: '#ffc107',
      semanticWarningShade10: shade(0.1, '#ffc107'),
      semanticWarningShade50: shade(0.5, '#ffc107'),
      semanticWarningTint80: tint(0.8, '#ffc107'),
      semanticWarningAlpha20: rgba('#ffc107', 0.2),
      semanticWarningAlpha5: rgba('#ffc107', 0.05),
      semanticDanger: '#e50a1f',
      semanticDangerShade10: shade(0.1, '#e50a1f'),
      semanticDangerShade20: shade(0.2, '#e50a1f'),
      semanticDangerShade50: shade(0.5, '#e50a1f'),
      semanticDangerTint80: tint(0.8, '#e50a1f'),
      semanticDangerTint90: tint(0.9, '#e50a1f'),
      semanticDangerAlpha20: rgba('#e50a1f', 0.2),
      semanticDangerAlpha5: rgba('#e50a1f', 0.05),
      semanticDangerDarkMode: '#f85d6d',
    },
    fontSizes: {
      s12: '0.75rem',
      s14: '0.875rem',
      s16: '1rem',
      s18: '1.125rem',
      s20: '1.25rem',
      s24: '1.5rem',
      s28: '1.75rem',
      s32: '2rem',
      s36: '2.25rem',
      s42: '2.625rem',
      s48: '3rem',
      s54: '3.375rem',
      s60: '3.75rem',
      s68: '4.25rem',
      s76: '4.75rem',
      s84: '5.25rem',
      s92: '5.75rem',
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      semiBold: 600,
    },
    motions: {
      default: 'ease-in-out',
    },
    motionSpeeds: {
      fast: '0.15s',
      normal: '0.3s',
      slow: '0.5s',
    },
    spacings: {
      s0: '0',
      s4: '0.25rem',
      s8: '0.5rem',
      s12: '0.75rem',
      s16: '1rem',
      s20: '1.25rem',
      s24: '1.5rem',
      s28: '1.75rem',
      s32: '2rem',
      s36: '2.25rem',
      s42: '2.625rem',
      s48: '3rem',
      s64: '4rem',
      s80: '5rem',
      s96: '6rem',
      s128: '8rem',
      s160: '10rem',
      s240: '15rem',
      s320: '20rem',
    },
  };

  return theme;
};

// TYPE CONSTRUCTORS
const allcolors = [...brandColors, ...semanticColors];

// Colors
export type AccentColors = (typeof accentColors)[number];
export type BrandColors = (typeof brandColors)[number];
export type DangerColors = (typeof dangerColors)[number];
export type DarkColors = (typeof darkColors)[number];
export type InfoColors = (typeof infoColors)[number];
export type LightColors = (typeof lightColors)[number];
export type PrimaryColors = (typeof primaryColors)[number];
export type SecondaryColors = (typeof secondaryColors)[number];
export type SemanticColors = (typeof semanticColors)[number];
export type SuccessColors = (typeof successColors)[number];
export type WarningColors = (typeof warningColors)[number];
export type AllColors = (typeof allcolors)[number];

// Fonts
export type FontSizes = (typeof fontSizes)[number];
export type FontWeights = (typeof fontWeights)[number];

// Icons
export type IconNames = (typeof iconNames)[number];

// Motions
export type Motions = (typeof motions)[number];
export type MotionSpeeds = (typeof motionSpeeds)[number];

// Positions
export type Positions = (typeof positions)[number];

// Shapes
export type BorderRadius = (typeof borderRadius)[number];

// Spacings
export type Spacings = (typeof spacings)[number];
