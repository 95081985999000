export const assetDownloadedFromPreview = 'Asset downloaded from preview';
export const assetDeletedFromGridView = 'Asset deleted from grid view';
export const assetDeletedFromPreview = 'Asset deleted from preview';
export const assetRenameFromPreview = 'Asset renamed from preview mode';
export const singleAssetDeletedBulkBar = 'Single asset deleted from bulk bar';
export const multipleAssetsDeletedBulkBar = 'Multiple assets deleted from bulk bar';
export const allAssetsDeletedBulkBar = 'All assets deleted from bulk bar';
export const singleFileUploadSuccess = 'Asset uploaded';
export const singleFileUploadFailure = 'Asset upload failed';
export const singleFileUploadCancelled = 'Asset upload cancelled';
export const mediaLibraryCreated = 'Media library created';
export const previewAssetsOpened = 'Preview opened';
export const mediaLibraryRenamed = 'Media library renamed';
export const assetsLoadedInfiniteScroll = 'Assets loaded with infinite scroll';
