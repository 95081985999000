import { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SidebarNavItem, useDropdownContext } from '@sendible/design-system';
import { SecondarySidebarStyled } from './index.styles';
import { defaultGetMediaLibrariesResponse, mediaLibraryIdParam } from '../../constants';
import mediaLibraryContext from '../../MediaLibraryContext';
import { useGetMediaLibraries } from '../../../../models/mediaLibraries';
import { MediaLibraryType } from '../../../../models/mediaLibraries/types';
import useMediaLibraryOptions from '../../utils/useMediaLibraryOptions';

export const Sidebar: FC = () => {
  const { data = defaultGetMediaLibrariesResponse, isLoading } = useGetMediaLibraries();
  const { getMediaLibraryOptions } = useMediaLibraryOptions();
  const { handleDropdown } = useDropdownContext();
  const { t } = useTranslation('media_library');

  const { activeMediaLibrary, setActiveMediaLibrary, sidebarIsVisible, setSidebarIsVisible, setAssetSearch } = useContext(mediaLibraryContext);
  const [searchParams] = useSearchParams();
  const { mediaLibraries } = data;

  const ctaButtonLabel = t('sibebar_new_library_button');
  const title = t('sidebar_title');
  const ariaMediaLibraryListLabel = t('aria_media_library_list');
  const ariaMediaLabel = t('aria_media_library');
  const ariaAddNewLabel = t('aria_add_new');

  const secondarySidebarContent = mediaLibraries.map((mediaLibrary: MediaLibraryType) => (
    <SidebarNavItem
      click={() => {
        setAssetSearch('');
        setActiveMediaLibrary(mediaLibrary);
        setSidebarIsVisible(false);
      }}
      data-testid={mediaLibrary.name}
      dropdownButtonClick={(options, ref) => {
        handleDropdown({
          fitContent: true,
          defaultPosition: 'bottom-end',
          ref,
          zIndex: 1,
        });
      }}
      dropDownOptions={getMediaLibraryOptions(mediaLibrary)}
      id={mediaLibrary.id}
      isActive={activeMediaLibrary?.id === mediaLibrary.id}
      key={mediaLibrary.id}
      label={mediaLibrary.name}
      ariaItemLabel={`${ariaMediaLabel}: ${mediaLibrary.name}`}
    />
  ));

  useEffect(() => {
    const findMediaLibrary = (id?: string | null) => mediaLibraries.find((mediaLibrary: MediaLibraryType) => mediaLibrary.id == id);

    if (mediaLibraries.length > 0) {
      const newMediaLibrary = findMediaLibrary(activeMediaLibrary?.id || searchParams.get(mediaLibraryIdParam));

      setActiveMediaLibrary(newMediaLibrary || mediaLibraries[0]);
    }
  }, [mediaLibraries]);

  return (
    <SecondarySidebarStyled
      ctaButtonIcon="plus"
      ctaButtonLabel={ctaButtonLabel}
      cta={() => null}
      isVisible={sidebarIsVisible}
      isLoading={isLoading}
      title={title}
      close={() => setSidebarIsVisible(false)}
      ariaLabel={ariaMediaLibraryListLabel}
      ariaAddNewLabel={ariaAddNewLabel}
    >
      {secondarySidebarContent}
    </SecondarySidebarStyled>
  );
};
