import { createContext, Dispatch, SetStateAction } from 'react';

export type SelectedAssetsContextType = {
  selectedAssetsIds: Array<string>;
  setSelectedAssetsIds: Dispatch<SetStateAction<string[]>>;
};

export default createContext<SelectedAssetsContextType>({
  selectedAssetsIds: [],
  setSelectedAssetsIds: () => null,
});
