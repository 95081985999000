import { ValidAndInvalidFileLists } from '../types';

export const isMimeTypeImageOrVideo = (mimeType: string): boolean => {
  const lcMimeType = mimeType.toLowerCase();

  return lcMimeType.includes('video') || lcMimeType.includes('image');
};

export const getValidAndInvalidFiles = (files: File[]): ValidAndInvalidFileLists => {
  return files.reduce(
    (allFiles: ValidAndInvalidFileLists, file: File) => {
      if (isMimeTypeImageOrVideo(file.type)) {
        allFiles.validFiles.push(file);
      } else {
        allFiles.invalidFiles.push(file);
      }

      return allFiles;
    },
    {
      validFiles: [],
      invalidFiles: [],
    }
  );
};
