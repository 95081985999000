import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { EVENTS, publish } from '@sendible/event-manager';
import { routeUtils } from '@sendible/common';
import { PageBaseKeys, routes } from '../../routes';

export const ProtectedRoutes = () => {
  const [
    {
      user: { canNavigate, featureTags },
    },
  ] = useBridgeContext();
  const { pathname } = useLocation();
  const pathWihtoutSubPaths = routeUtils.getPathWithoutSubPaths(pathname);
  const availableFeatureTagsArray = Object.keys(featureTags);
  const routeConfigObject = routes[pathWihtoutSubPaths as PageBaseKeys] ? routes[pathWihtoutSubPaths as PageBaseKeys] : null;
  const routeFeatureTagName = routeConfigObject?.featureTag;
  const routeFeautreRestrictionName = routeConfigObject?.featureRestriction;
  let featureIsRestricted = false;

  if (routeFeautreRestrictionName) {
    const featureStatus = window.js_featurMngr.getFeatureStatus(routeFeautreRestrictionName);

    featureIsRestricted = featureStatus == 'restricted' || featureStatus == 'limited';
  }

  if (!canNavigate || featureIsRestricted) {
    setTimeout(() => {
      publish(EVENTS.CHANGE_PAGE, '#manage/subscription');
    }, 0);

    return null;
  }

  if (routeFeatureTagName && availableFeatureTagsArray.indexOf(routeFeatureTagName) > -1) {
    return <Outlet />;
  }

  return <Navigate to="/#publish" />;
};
