import { FC, useContext, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { getRelativeTime } from '@sendible/common';
// import { MediaCard, useDropdownContext } from '@sendible/design-system';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useInView } from 'react-intersection-observer';
import { useInfiniteGetAssets } from '@sendible/frontendv2/src/models/assets';
// import { AssetType } from '@sendible/frontendv2/src/models/assets/types';
import { ContentStyled, GridStyled } from './index.styles';
import LoadingContentSection from './LoadingContentSection';
import Skeletons from './Skeletons';
import ErrorBoundary from '../../errorBoundaries/errorBoundary';
import PreviewAssets from '../PreviewAssets';
import MediaLibraryContext from '../../MediaLibraryContext';
import { Header } from '../Header';
import { LoadingHeader } from '../Header/LoadingHeader';
// import SelectedAssetsContext from '../../SelectedAssetsContext';

export const ContentSection: FC = () => {
  const { activeMediaLibrary, perPage } = useContext(MediaLibraryContext);
  const { ref, inView } = useInView();
  const { status, data, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteGetAssets();
  // const { dropdownState, handleDropdown } = useDropdownContext();
  // const { t } = useTranslation('media_library');

  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [initialPreviewAssetId, setInitialPreviewAssetId] = useState('');

  // const { selectedAssetsIds, setSelectedAssetsIds } = useContext(SelectedAssetsContext);

  // const ariaMediaCardLabel = t('aria_media_item');

  const [
    {
      user: {
        // language,
        isWhiteLabel,
      },
    },
  ] = useBridgeContext();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  // const handleAssetAction = (action: string, asset: AssetType) => {
  // if (action === 'delete') {
  //   openModal({
  //     children: (
  //       <DeleteAssetModalContent
  //         asset={asset}
  //         pendoEvent={assetDeletedFromGridView}
  //       />
  //     ),
  //     headingTitle,
  //     variation: 'small',
  //   });
  // }
  // };

  // const handleSelectClick = (asset: AssetType) => {
  //   let newList = [];

  //   if (selectedAssetsIds?.includes(asset.id)) {
  //     newList = [...selectedAssetsIds];

  //     newList.splice(newList.indexOf(asset.id), 1);
  //   } else {
  //     newList = selectedAssetsIds ? [...selectedAssetsIds, asset.id] : [asset.id];
  //   }
  //   setSelectedAssetsIds(newList);
  // };

  const LoadingContentSectionJSX = (
    <LoadingContentSection perPage={perPage}>{activeMediaLibrary?.id ? <Header /> : <LoadingHeader />}</LoadingContentSection>
  );

  const ContentSectionJSX = (
    <ContentStyled>
      <Header />
      <GridStyled
        horizontalGap="s16"
        verticalGap="s16"
        padding={['s24', 's24', 's24', 's24']}
      >
        {/* {data?.pages.map((page) =>
          page.data.map((asset) => (
            <MediaCard
              ariaMediaCardLabel={`${ariaMediaCardLabel} - ${asset.name}`}
              currentSelectedDropdownRefId={dropdownState?.ref?.id || ''}
              dropdownButtonClick={(options, parentRef) =>
                handleDropdown({
                  options,
                  fitContent: true,
                  defaultPosition: 'bottom-end',
                  ref: parentRef,
                  zIndex: 2,
                  select: (_, action) => handleAssetAction(action, asset),
                })
              }
              // duration={asset.metadata.duration}
              id={asset.id}
              isSelected={selectedAssetsIds?.includes(asset.id)}
              name={asset.name}
              key={asset.id}
              open={() => {
                setPreviewOpen(true);
                setInitialPreviewAssetId(asset.id);
              }}
              options={[{ label: t('dropdown_delete'), action: 'delete' }]}
              select={() => handleSelectClick(asset)}
              showCheckboxWithoutHover={!!selectedAssetsIds?.length}
              tabIndex={0}
              testId={`asset-${asset.name}`}
              thumbUrl={asset.thumbnail}
              type={asset.objectType}
              updateDate={getRelativeTime(language, asset.updatedAt)}
              uploadingText={t('asset_uploading_state_text')}
            />
          ))
        )} */}
        {isFetchingNextPage && hasNextPage && <Skeletons perPage={perPage} />}
      </GridStyled>
      <div
        className="loader"
        ref={ref}
      />
      {isPreviewOpen && initialPreviewAssetId && data && (
        <ErrorBoundary
          isWhiteLabel={isWhiteLabel}
          errorSection="previewAssetsSection"
        >
          <PreviewAssets
            requestClose={() => {
              setInitialPreviewAssetId('');
              setPreviewOpen(false);
            }}
            assetList={data.pages.flatMap((page) => page.data)}
            initialAssetId={initialPreviewAssetId}
          />
        </ErrorBoundary>
      )}
    </ContentStyled>
  );

  return status === 'loading' ? LoadingContentSectionJSX : ContentSectionJSX;
};
