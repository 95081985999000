import { fetchWithHeaders } from '@sendible/common';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { AI_ASSIST_QUICK_ACTIONS } from '../../components/AiAssist/consts';
import { AiAssistUrls } from '../../constants/urls';
import { QuickActions } from '../../components/AiAssist/types';

type GenerateContentType = {
  composeText: string;
  sessionId: string;
  previousResults: AiAssistResults[];
  promptValue: string;
  targetAudience: string;
  toneOfVoice: string;
};

type RegenerateContentType = {
  quickAction: QuickActions;
  sessionId: string;
  content: string;
  previousResults: AiAssistResults[];
  promptValue: string;
  targetAudience: string;
  toneOfVoice: string;
};

const getErrorMessage = (error: unknown, defaultErrorMessage: string) => {
  if (error instanceof AxiosError) {
    if (error.response?.data?.error?.message) {
      return error.response.data.error.message;
    }
  } else {
    return defaultErrorMessage;
  }
};

export const useAiAssistModel = () => {
  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const { t } = useTranslation('ai_assist');

  const fetchApiHandler = async (url: string, previousResults: AiAssistResults[], promptValue: string) => {
    try {
      const results = await fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      });

      if (results && results.content) {
        return [
          ...previousResults,
          {
            prompt: promptValue,
            content: results.content,
          },
        ];
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const { code, message } = error.response.data.error;

      const getTranslatedMessage = (): string => {
        if (code === 'ThirdParty') return t('third_party_error_message');
        if (message === 'prompt argument is invalid. Must meet the minimum requirements.') return t('invalid_argument_error_message');
        if (message === 'Prompt is too long.') return t('prompt_too_long_error_message');

        return t('default_error_message');
      };

      const translatedMessage = getTranslatedMessage();

      const errorMessage = getErrorMessage(error, translatedMessage);

      throw Error(errorMessage);
    }
  };

  const generateContent = async ({ composeText, previousResults, promptValue, sessionId, targetAudience, toneOfVoice }: GenerateContentType) => {
    const targetAudienceParameter = targetAudience ? `&targetAudience=${targetAudience}` : '';
    const toneParameter = toneOfVoice ? `&tone=${toneOfVoice}` : '';
    const sessionIdParameter = `&sessionId=${sessionId}`;
    const extraParameters = `${toneParameter}${targetAudienceParameter}${sessionIdParameter}`;
    let updatedPromptText = promptValue;

    if (composeText) {
      updatedPromptText = `${promptValue} for the text between three quotes, concatenated the result '''${composeText}'''`;
    }

    const parameters = `prompt=${encodeURIComponent(updatedPromptText)}${extraParameters}`;
    const url = `${AiAssistUrls.content_generator}?access_token=${accessToken}&${parameters}`;

    const results = await fetchApiHandler(url, previousResults, promptValue);

    return results || null;
  };

  const regenerateContent = async ({
    quickAction,
    sessionId,
    content,
    previousResults,
    promptValue,
    targetAudience,
    toneOfVoice,
  }: RegenerateContentType) => {
    const socialNetwork = AI_ASSIST_QUICK_ACTIONS.customiseActionsArray.find((tab) => tab.action === quickAction);
    const targetAudienceParameter = targetAudience ? `&targetAudience=${targetAudience}` : '';
    const toneParameter = toneOfVoice ? `&tone=${toneOfVoice}` : '';
    const extraParameters = `${toneParameter}${targetAudienceParameter}&sessionId=${sessionId}`;
    const parameters = `prompt=${encodeURIComponent(content)}${extraParameters}`;
    const urlWithParameters = `${AiAssistUrls.content}?access_token=${accessToken}&${parameters}`;
    let url = '';

    if (socialNetwork) {
      url = `${urlWithParameters}&option=rephrase&socialNetwork=${socialNetwork.action}`;
    } else {
      url = `${urlWithParameters}&option=${quickAction}`;
    }

    const results = fetchApiHandler(url, previousResults, promptValue);

    return results || null;
  };

  return {
    generateContent,
    regenerateContent,
  };
};
