import { useDropdownContext } from './context';
import { DropdownOld } from '../../molecules/DropdownOld';

export const GlobalDropdown = () => {
  const { dropdownState, isDropdownVisible, closeDropdown } = useDropdownContext();

  const handleSelect = (option: string, action: string, index: number) => {
    if (dropdownState.select) dropdownState.select(option, action, index);
    closeDropdown();
  };

  return (
    <DropdownOld
      close={closeDropdown}
      customClass="global-dropdown"
      defaultPosition={dropdownState.defaultPosition}
      fitContent={dropdownState.fitContent}
      isVisible={isDropdownVisible}
      options={dropdownState.options || []}
      ref={dropdownState.ref}
      select={(option, action, index) => handleSelect(option, action, index)}
      testId="global-dropdown"
      zIndex={dropdownState.zIndex}
    />
  );
};
