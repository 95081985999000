import { FC, useContext, useRef, useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BulkActionsBar, Button, Heading, Input, useDropdownContext } from '@sendible/design-system';
import { useUploadFiles } from '@sendible/frontendv2/src/components/BackgroundUploader/useUploadFiles';
import { ContainerStyled } from './index.styles';
import mediaLibraryContext from '../../MediaLibraryContext';
import { BoxStyled } from '../../index.styles';
import { useInfiniteGetAssets } from '../../../../models/assets';
import { searchInputThrottle } from '../../constants';
import useMediaLibraryOptions from '../../utils/useMediaLibraryOptions';
import SelectedAssetsContext from '../../SelectedAssetsContext';
import usePostToCompose from '../PreviewAssets/usePostToCompose';

export const Header: FC = () => {
  const { activeMediaLibrary, setSidebarIsVisible, assetSearch, setAssetSearch, isMobile } = useContext(mediaLibraryContext);
  const [assetSearchTimeoutId, setAssetSearchTimeoutId] = useState<NodeJS.Timeout>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef(null);
  const { getMediaLibraryOptions } = useMediaLibraryOptions();
  const { handleDropdown } = useDropdownContext();
  const { selectedAssetsIds, setSelectedAssetsIds } = useContext(SelectedAssetsContext);
  const { data } = useInfiniteGetAssets();
  const { postToCompose, setMediaIds } = usePostToCompose(null, () => {
    return null;
  });

  const [isBulkPostingDisabled, setIsBulkPostingDisabled] = useState(true);

  const { t } = useTranslation('media_library');
  const { uploadFiles } = useUploadFiles();

  const uploadButtonLabel = t('header_upload_button');
  const ariaUploadMediaLabel = t('aria_upload_media');
  const ariaMediaLibraryOptionsLabel = t('aria_media_library_options');
  const ariaSearchCurrentLabel = t('aria_search_current');
  const dropdownOptions = getMediaLibraryOptions(activeMediaLibrary) || [];
  const BULK_POST_ACTION = 'bulkpost';

  useEffect(() => {
    let hasVideo = false;

    const selectedAssetData = data?.pages
      .map((page) => page.data)
      .flat()
      .filter((asset) => {
        const isSelected = selectedAssetsIds.includes(asset.id);

        if (!isSelected) {
          return false;
        }

        if (asset.objectType === 'video') {
          hasVideo = true;
        }

        return true;
      });

    const selectedAssetsLength = selectedAssetData?.length || 0;

    if (selectedAssetsLength === 0 || selectedAssetsLength > 15 || (hasVideo && selectedAssetsLength > 1)) {
      setIsBulkPostingDisabled(true);
    } else {
      setIsBulkPostingDisabled(false);
    }
  }, [selectedAssetsIds]);

  useEffect(() => {
    setMediaIds(selectedAssetsIds);
  }, [selectedAssetsIds]);

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files) {
      return;
    }

    uploadFiles(Array.from(files));
  };

  const onUploadButtonClick = () => {
    hiddenFileInput?.current?.click();
  };

  const assetSearchHandleThrottled = (value: string) => {
    const timeoutId = setTimeout(() => setAssetSearch(value), searchInputThrottle);

    if (assetSearchTimeoutId) {
      clearInterval(assetSearchTimeoutId);
    }
    setAssetSearchTimeoutId(timeoutId);
  };

  const handleBulkAction = (action: string) => {
    type OptionsType = {
      [key: string]: () => void;
    };

    const options: OptionsType = {
      // [BULK_DELETE_ACTION]: () =>
      //   openModal({
      //     children: <DeleteMultipleAssetsModalContent pendoEvent={getPendoEventName()} />,
      //     headingTitle: modalHeadingText,
      //     variation: 'small',
      //   }),
      [BULK_POST_ACTION]: () => postToCompose(),
    };

    if (options[action]) {
      options[action]();
    }
  };

  return (
    <ContainerStyled padding={['s8', 's24', 's8', 's24']}>
      <BoxStyled
        contentAlignment="center"
        contentJustify="space-between"
        padding={['s0', 's0', 's16', 's0']}
        overflow="nowrap"
      >
        <Box
          className="name-container"
          flex={1}
          horizontalGap="s28"
        >
          <Heading
            align="left"
            color="brandDark"
            semantic="h4"
            variation="component-title_16"
            data-testid="media-library-name"
            maxLines={1}
          >
            {activeMediaLibrary?.name}
          </Heading>
          {isMobile && (
            <Button
              type="button"
              onClick={() => setSidebarIsVisible(true)}
              icon="select"
              size={12}
              data-testid="open-sidebar-mobile"
            />
          )}
        </Box>
        <Box
          horizontalGap="s12"
          className="button-container"
          contentAlignment="center"
        >
          <Button
            appearance="default"
            aria-label={ariaUploadMediaLabel}
            type="button"
            icon="upload_cloud"
            iconPosition="left"
            size={14}
            variation="outline"
            floatAppearance="primary"
            label={uploadButtonLabel}
            onClick={onUploadButtonClick}
            disabled={!data}
            data-testid="upload-header-button"
          />
          {getMediaLibraryOptions(activeMediaLibrary) && (
            <Button
              aria-haspopup="true"
              aria-controls="menu"
              aria-label={ariaMediaLibraryOptionsLabel}
              type="button"
              onClick={() =>
                handleDropdown({
                  options: dropdownOptions,
                  ref: dropdownRef.current,
                  zIndex: 5,
                })
              }
              icon="more_horizontal"
              id="header-dropdown"
              ref={dropdownRef}
              size={12}
              data-testid="header-dropdown-button"
            />
          )}
        </Box>
        <input
          type="file"
          multiple
          accept="video/*, image/*"
          hidden
          onChange={onFilesChange}
          onClick={(event) => {
            // this is forcing onChange to be triggered even if you select the same file
            event.currentTarget.value = '';
          }}
          ref={hiddenFileInput}
          data-testid="upload-header-input"
        />
      </BoxStyled>
      {selectedAssetsIds.length > 0 ? (
        <BulkActionsBar
          act={(action) => handleBulkAction(action)}
          actionButtons={[
            {
              action: BULK_POST_ACTION,
              icon: 'send',
              isDisabled: isBulkPostingDisabled,
              size: 16,
              label: isBulkPostingDisabled ? 'Post up to 15 images or 1 video at once' : ' Bulk Post',
            },
            { icon: 'trash', size: 16, label: 'Delete', action: 'delete' },
          ]}
          deselectAll={() => setSelectedAssetsIds([])}
          maxItemsCount={
            data?.pages
              .map((page) => page.data)
              .map((assets) => assets.length)
              .reduce((a, b) => a + b, 0) || 0
          }
          selectAll={() => {
            const allAssetsIds = data?.pages
              .map((page) => page.data)
              .map((assets) => assets.map((asset) => asset.id))
              .flat();

            setSelectedAssetsIds(allAssetsIds || []);
          }}
          selectedItemsCount={selectedAssetsIds.length}
          selectedItemsLabel={t('bulkbar_selected')}
        />
      ) : (
        <Input
          label={t('header_search')}
          size="md"
          testId="search-input"
          value={assetSearch}
          change={(currentAssetSearch) => {
            assetSearchHandleThrottled(currentAssetSearch);
          }}
          type="text"
          icon="search"
          ariaLabel={ariaSearchCurrentLabel}
        />
      )}
    </ContainerStyled>
  );
};
