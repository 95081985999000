/* eslint-disable react/no-danger */
import { useEffect } from 'react';
import { BoxFullWidth, ButtonBack, ButtonForward, Container, PaginationContainer, PaginationControlContainer } from './index.styles';
import { Icon } from '../../atoms/Icon';
import { Box } from '../../atoms/Box';
import { Button } from '../Button';

export enum NotificationTypeEnum {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}
export const positions = ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end'] as const;
export const defaultPosition = 'top';
export type ToastPositions = 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end';
export interface ToastNotificationProps extends Component {
  /**
   * Notification text. Optional.
   */
  notificationText?: string | JSX.Element | undefined;
  /**
   * Notification type. Optional.
   */
  type?: NotificationTypeEnum;
  /**
   * Icon. Icon will be applied relative to type used (see type). Optional.
   */
  icon?: boolean;
  /**
   * Close callback. Optional.
   */
  close?: () => void;
  /**
   * Position. Determine where on the screen the Toast should be displayed. Optional.
   */
  position?: ToastPositions;
  /**
   * Show slow button. Determine whether to show the close button. Optional.
   */
  showCloseButton?: boolean;
  /**
   * action. Displays an action with callback. Optional.
   */
  action?: {
    text: string;
    callback: () => void;
  };
  /**
   * hideTimeout. Seconds before auto hiding toast. Optional.
   */
  hideTimeout?: null | number;
  /**
   * showAnimations. Optional.
   */
  showAnimations?: boolean;
  /**
   * isBanner. Sets toast to banner mode. Optional.
   */
  isBanner?: boolean;
  /**
   * pagination. Sets pagination props for banner mode. Optional.
   */
  pagination?: {
    back?: () => void;
    forward?: () => void;
    text?: string;
    showBackArrow: boolean;
    showForwardArrow: boolean;
  };
}

const getIcon = (type: string) => {
  if (type === 'error') {
    return 'danger';
  }
  if (type === 'success') {
    return 'check';
  }
  if (type === 'warning') {
    return 'warning';
  }
  if (type === 'info') {
    return 'info';
  }

  return 'info';
};

/**
 * ToastNotification
 */
export const Toast = (props: ToastNotificationProps) => {
  const {
    action = null,
    icon,
    notificationText,
    position = 'top',
    showCloseButton = true,
    type = NotificationTypeEnum.Info,
    close = () => null,
    hideTimeout = null,
    showAnimations = true,
    isBanner = false,
    pagination = { text: '', showBackArrow: false, showForwardArrow: false, back: () => null, forward: () => null },
  } = props;

  let hideTimeoutInstance: undefined | NodeJS.Timeout;

  const animations =
    showAnimations && !isBanner
      ? {
          initial: { scale: 0 },
          animate: { scale: 1 },
        }
      : {};

  useEffect(() => {
    if (hideTimeout) {
      hideTimeoutInstance = setTimeout(() => {
        close();
      }, hideTimeout * 1000);
    } else {
      clearTimeout(hideTimeoutInstance);
    }

    return () => {
      clearTimeout(hideTimeoutInstance);
    };
  }, [hideTimeout]);

  return (
    <Container
      type={type}
      data-testid={`toast-${type}`}
      position={position}
      {...animations}
    >
      {isBanner && pagination?.text && (
        <PaginationControlContainer>
          <ButtonBack
            onClick={pagination.back}
            icon="chevron_left"
            disabled={!pagination.showBackArrow}
          />
          <ButtonForward
            onClick={pagination.forward}
            icon="chevron_right"
            disabled={!pagination.showForwardArrow}
          />
        </PaginationControlContainer>
      )}

      {icon && (
        <Box margin={['s0', 's12', 's0', 's0']}>
          <Icon
            name={getIcon(type)}
            className="icon"
          />
        </Box>
      )}
      <BoxFullWidth
        orientation="row"
        contentJustify="space-between"
        margin={['s0', 's12', 's0', 's0']}
      >
        <Box orientation="column">
          {isBanner && pagination?.text && <PaginationContainer>{pagination.text}</PaginationContainer>}
          <Box alignSelf="center">
            <p dangerouslySetInnerHTML={{ __html: (notificationText as string) || '' }} />
          </Box>
        </Box>
      </BoxFullWidth>
      {action && (
        <Box alignSelf="center">
          <Button
            label={action.text}
            onClick={action.callback}
          />
        </Box>
      )}
      {showCloseButton && (
        <Box alignSelf="center">
          <Button
            className="close"
            data-testid="toast-notification-close"
            onClick={() => close()}
            icon="close"
            size={12}
          />
        </Box>
      )}
    </Container>
  );
};
