import { createContext, Dispatch, SetStateAction } from 'react';
import { defaultMediaLibrary } from './constants';
import { MediaLibraryType } from '../../models/mediaLibraries/types';

export type MediaLibraryContextType = {
  activeMediaLibrary: MediaLibraryType;
  setActiveMediaLibrary: Dispatch<SetStateAction<MediaLibraryType>>;
  sidebarIsVisible: boolean;
  setSidebarIsVisible: Dispatch<SetStateAction<boolean>>;
  assetSearch: string;
  setAssetSearch: Dispatch<SetStateAction<string>>;
  isMobile: boolean;
  setIsMobile: Dispatch<SetStateAction<boolean>>;
  perPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
};

export default createContext<MediaLibraryContextType>({
  activeMediaLibrary: defaultMediaLibrary,
  setActiveMediaLibrary: () => null,
  sidebarIsVisible: false,
  setSidebarIsVisible: () => null,
  assetSearch: '',
  setAssetSearch: () => null,
  isMobile: false,
  setIsMobile: () => null,
  perPage: 50,
  setPerPage: () => null,
});
