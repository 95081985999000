import styled, { css } from 'styled-components';

interface HeaderProps {
  showLine: boolean;
}

interface ListProps {
  $isVisible: boolean;
  $fitContent?: boolean;
  $zIndex: number;
}

export const MobileWrapper = styled.div<Pick<ListProps, '$isVisible'>>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      motions,
      motionSpeeds,
    },
    $isVisible,
  }) => css`
    display: none;

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      bottom: 0;
      display: block;
      left: 0;
      overflow: hidden;
      position: fixed;
      transition: all ${motionSpeeds.normal} ${motions.default};
      width: 100%;
      z-index: 100;

      ${!$isVisible &&
      css`
        bottom: -100%;
        opacity: 0;
        visibility: hidden;
      `}
    }
  `}
`;

export const Header = styled.header<HeaderProps>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      borderRadius,
      colors,
    },
    showLine,
  }) => css`
    align-items: center;
    background: ${colors.brandLight};
    border-radius: ${borderRadius.sm} ${borderRadius.sm} 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    left: 0;
    padding: 2rem 1.5rem;
    position: sticky;
    top: 0;
    width: 100%;

    ${showLine &&
    css`
      box-shadow: 0 1px 0 ${colors.brandDarkAlpha20};
    `}

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      padding: 0.5rem;
      padding-left: 1.5rem;
    }
  `}
`;

export const List = styled.div<ListProps>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      borderRadius,
      colors,
    },
    $fitContent,
    $zIndex,
  }) => css`
    display: flex;
    position: fixed;
    z-index: ${$zIndex};

    .dropdown-contentarea {
      background: ${colors.brandLight};
      box-shadow: 0 0 0 1px ${colors.brandDarkAlpha10}, 0 0.75rem 0.5rem -0.25rem ${colors.brandDarkAlpha15},
        0 0.25rem 0.25rem -0.125rem ${colors.brandDarkAlpha20};
      border-radius: ${borderRadius.sm};
      display: flex;
      flex-direction: column;
      max-height: 14rem;
      overflow: hidden;
      width: ${$fitContent ? 'fit-content' : '14rem'};
    }

    ul {
      overflow-y: overlay;
      padding: 0.5rem 2px;
      margin: 0;

      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: #d2d2d3;
        border-radius: 8px;
        height: 8px;
        -webkit-border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a6a5a7;
      }
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      background: ${colors.brandLight};
      border-radius: 0;
      margin: 0;
      max-height: calc(100vh - 4rem);
      overflow-y: auto;
      padding: 0;
      position: static;
      width: 100%;

      .dropdown-contentarea {
        box-shadow: none;
        width: 100%;
      }
    }
  `}
`;

export const Option = styled.button`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      fontSizes,
    },
  }) => css`
    background: transparent;
    border: 0;
    border-radius: 0;
    color: ${colors.brandDark};
    cursor: pointer;
    display: -webkit-box;
    flex-shrink: 0;
    font-size: ${fontSizes.s14};
    height: 2.5rem;
    overflow: hidden;
    padding: 0 1.5rem;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    &:hover {
      background: ${colors.brandPrimaryAlpha5};
      color: ${colors.brandPrimary};
    }

    &:active {
      background: ${colors.brandPrimaryAlpha10};
      color: ${colors.brandPrimary};
    }

    &:focus-visible {
      color: ${colors.brandPrimary};
      outline-color: ${colors.brandPrimary};
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      font-size: ${fontSizes.s16};
      height: 3.5rem;

      &:focus-visible {
        background: ${colors.brandPrimaryAlpha5};
        color: ${colors.brandPrimary};
        outline-color: transparent;
      }
    }
  `}
`;

export const Spacer = styled.span`
  ${({ theme: { colors } }) => css`
    display: block;
    background: ${colors.brandDarkAlpha10};
    min-height: 1px;
    margin: 0.5rem 0;
    width: 100%;
  `}
`;

export const Backdrop = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      background: ${colors.brandDarkAlpha90};
      cursor: pointer;
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 99;
    }
  `}
`;
