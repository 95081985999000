import { fetchWithHeaders, getErrorFromFetchWithHeaders } from '@sendible/common';
import { ReportsUrls } from '../../constants/urls';
import { useUrlWithCredentials } from '../../data-layer/useUrlWithCredentials';

export type PostModel = {
  comments: number;
  likes: number;
  message: string;
  postPicture: string;
  profileName: string;
  profilePicture: string;
  socialNetwork: string;
  uid: string;
  dateMessage: string;
};

export const useReportsModel = () => {
  const getUrlWithCredentials = useUrlWithCredentials();
  const quickReports = () => {
    try {
      const url = getUrlWithCredentials(ReportsUrls.quickReports);

      return fetchWithHeaders({
        method: 'GET',
        url,
        isClassicApi: true,
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getCampaignAudienceGrowth = (campaignId: number) => {
    try {
      const url = `${getUrlWithCredentials(ReportsUrls.campaignAudienceGrowth)}&campaignId=${campaignId}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        isClassicApi: false,
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getReportCampaignOverview = async (campaignId: number) => {
    try {
      const url = `${getUrlWithCredentials(ReportsUrls.campaignOverview)}&campaignId=${campaignId}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        isClassicApi: false,
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getCampaignPosts = async (campaignId: number, page: number, orderBy: string, socialNetwork: string) => {
    try {
      let parameters = `campaignId=${campaignId}&page=${page}`;

      if (orderBy !== '') {
        parameters = `${parameters}&orderBy=${orderBy}`;
      }
      if (socialNetwork !== '') {
        parameters = `${parameters}&socialNetwork=${socialNetwork}`;
      }

      const url = `${getUrlWithCredentials(ReportsUrls.campaignPosts)}&${parameters}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        isClassicApi: false,
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  return {
    quickReports,
    getCampaignAudienceGrowth,
    getReportCampaignOverview,
    getCampaignPosts,
  };
};
