import { useTranslation } from 'react-i18next';
import { useGetMediaLibraries } from '../../../models/mediaLibraries/index';
// import { DeleteMediaLibraryModalContent } from '../components';
import { defaultGetMediaLibrariesResponse, defaultMediaLibrary } from '../constants';
// import { useModalContext } from '../../../components/GlobalModal';
// import { CreateMediaLibraryModalContent } from '../components/CreateMediaLibraryModalContent/index';

export default function useMediaLibraryOptions() {
  const { data = defaultGetMediaLibrariesResponse } = useGetMediaLibraries();
  const { canDelete } = data;
  // const { openModal } = useModalContext();
  const { t } = useTranslation('media_library');

  // const headingTitle = t('delete_media_library_modal_title');

  return {
    getMediaLibraryOptions: (mediaLibrary = defaultMediaLibrary) => {
      const del = {
        label: t('dropdown_delete'),
        isHighlighted: true,
        action: 'delete',
        // click: () =>
        //   openModal({
        //     children: <DeleteMediaLibraryModalContent mediaLibrary={mediaLibrary} />,
        //     headingTitle,
        //     variation: 'small',
        //   }),
      };
      const rename = {
        label: t('dropdown_rename'),
        action: 'rename',
        // click: () =>
        //   openModal({
        //     children: <CreateMediaLibraryModalContent mediaLibrary={mediaLibrary} />,
        //     variation: 'medium',
        //   }),
      };

      const optionsList = [rename];

      if (canDelete) {
        optionsList.push(del);
      }

      return optionsList;
    },
  };
}
