import { MutationFunction, UseInfiniteQueryOptions, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

export enum MutationMethodEnum {
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export type APIResponseType = {
  count?: number;
  data?: unknown[];
  totalCount?: number;
};
interface innerErrorType {
  code: string;
  message: string;
  innerError: innerErrorType;
}
export interface APIErrorType {
  //  A high level enum explaining what type of error it is
  code?: string;
  // description of the error
  message: string;
  // returned by authentication API
  type?: string;
  // element that originated the error
  target?: string;
  // With each innerError it reveals a more specific reason as to what the error is
  innerError?: {
    code: string;
    message: string;
    innerError: innerErrorType;
  };
  // Details if there was more than one error.
  details?: APIErrorType[];
}

export type QueryKey = [string, object?];
export type InfiniteQueryParamsType = {
  [key: string]: unknown;
  perPage: number;
};

export type InfiniteQueryKey = [string, InfiniteQueryParamsType];
export type TVariables = object;

export type UseMutationOptionsWithGenerics<TData> = UseMutationOptions<TData, APIErrorType, TVariables>;
export type UseQueryOptionsWithGenerics<TData> = UseQueryOptions<APIResponseType, APIErrorType, TData, QueryKey>;
export type UseInfiniteQueryOptionsWithGenerics<TData> = UseInfiniteQueryOptions<
  APIResponseType,
  APIErrorType,
  TData,
  APIResponseType,
  InfiniteQueryKey
>;

export interface UseMutationWithAccessTokenParamsType<T, TVar> {
  method: MutationMethodEnum;
  endpoint: string;
  options?: UseMutationOptionsWithGenerics<T>;
  mutationFn?: MutationFunction<T, TVar>;
}
