import { Routes, Route } from 'react-router-dom';
import Main from './FrontendV2.styles';
import { LegacySideBar, ProtectedRoutes } from './components';
import { pages } from './routes';
import MediaLibrary from './pages/MediaLibrary';
import { Campaigns } from './pages/Campaigns';
import { Reports } from './pages/Reports';

export const FrontendV2Page = () => (
  <Main id="v2">
    <LegacySideBar />
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route
          path={pages.MediaLibrary.base}
          element={<MediaLibrary />}
        />
        <Route
          path={`${pages.Campaigns.base}/:id?`}
          element={<Campaigns />}
        />
        <Route
          path={`${pages.Measure.base}/*`}
          element={<Reports />}
        />
      </Route>
    </Routes>
  </Main>
);
