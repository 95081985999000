/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useRef, ForwardedRef, KeyboardEvent } from 'react';
import { Actions, BackgroundFiller, Container, Details, Image, Progress, Time } from './index.styles';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Text';
import { OptionProps } from '../DropdownOld';

export const mediaTypes = ['Image', 'Video'] as const;

export interface MediaCardProps extends Component {
  /**
   * Aria Media Card label. Optional
   */
  ariaMediaCardLabel?: string;
  /**
   * Current dropdown context button ref id. Used to control this component hover state. Required.
   */
  currentSelectedDropdownRefId: string;
  /**
   * Dropdown button event emitter. Required.
   */
  dropdownButtonClick: (options: OptionProps[], ref: ForwardedRef<HTMLButtonElement>) => void;
  /**
   * Video duration time. Optional.
   */
  duration?: number;
  /**
   * Media type.
   */
  extension?: string;
  /**
   * Media id. Required.
   */
  id: string;
  /**
   * Tells if the mediacard is selected. Optional.
   */
  isSelected?: boolean;
  /**
   * If loading, receives loading percentage updates. Optional.
   */
  loadingProgress?: number;
  /**
   * File name. Required.
   */
  name: string;
  /**
   * Open event emitter. Optional.
   */
  open?: (id: string) => void;
  /**
   * List of options. Required.
   */
  options: OptionProps[];
  /**
   * select event emitter. Optional.
   */
  select?: (value: boolean) => void;
  /**
   * Display the checkbox by default. Optional.
   */
  showCheckboxWithoutHover?: boolean;
  /**
   * Tab index for MediaCard. Optional.
   */
  tabIndex?: number;
  /**
   * URL of the thumbnail. Optional.
   */
  thumbUrl?: string;
  /**
   * File type. Required.
   */
  type: (typeof mediaTypes)[number];
  /**
   * Creation date or last update date. Required.
   */
  updateDate: string;
  /**
   * Translated text for uploading state. Required.
   */
  uploadingText: string;
}

/**
 * MediaCard
 */
export const MediaCard = (props: MediaCardProps) => {
  const {
    ariaMediaCardLabel = '',
    className = '',
    currentSelectedDropdownRefId,
    customClass = '',
    dropdownButtonClick,
    duration = 0,
    extension,
    name,
    type,
    id,
    isSelected = false,
    loadingProgress,
    open,
    options,
    select,
    showCheckboxWithoutHover = false,
    tabIndex = -1,
    testId = 'mediacard',
    thumbUrl,
    uploadingText,
    updateDate,
  } = props;

  const [isTabSelected, setIsTabSelected] = useState(false);
  const [isSelectedState, setIsSelectedState] = useState<boolean>(isSelected);
  const ref = useRef(null);

  const buttonRefId = `mediacard-dropdown-button-${id}`;

  const handleSelect = () => {
    setIsSelectedState((prev) => !prev);
    select && select(isSelectedState);
  };

  const getTime = () => {
    const mins = Math.floor(duration / 60000);
    const secs = ((duration % 60000) / 1000).toFixed(0);

    return `${mins}:${Number(secs) < 10 ? '0' : ''}${secs}`;
  };

  const filename = extension ? `${name}.${extension}` : name;

  useEffect(() => {
    setIsSelectedState(isSelected);
  }, [isSelected]);

  return (
    <Container
      className={`mediacard ${customClass} ${className}`}
      data-testid={testId}
      isSelected={isSelectedState}
      isDropdownVisible={currentSelectedDropdownRefId === buttonRefId}
    >
      <Image
        isSelected={isSelectedState}
        showCheckbox={isSelectedState || showCheckboxWithoutHover}
        onKeyDown={(e: KeyboardEvent<HTMLElement>) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            open && open(id);
          }
          if (e.key === 'Tab') {
            setIsTabSelected(true);
          }
          if (e.key === 'Escape') {
            setIsTabSelected(false);
          }
          if (e.key === ' ') {
            e.preventDefault();
            handleSelect();
          }
        }}
        tabIndex={tabIndex}
        data-testid={`${testId}-image`}
        aria-label={ariaMediaCardLabel}
      >
        {!loadingProgress ? (
          <>
            <Checkbox
              check={handleSelect}
              customClass={isTabSelected ? 'show-checkbox' : ''}
              id={id}
              initialState={isSelectedState ? 'checked' : 'unchecked'}
              label="Checkbox"
              size="md"
              tabIndex={-1}
              testId={`${testId}-checkbox`}
            />
            <BackgroundFiller
              data-testid={`${testId}-button`}
              id={id}
              onClick={() => open && open(id)}
              tabIndex={-1}
            />
            {thumbUrl &&
            // videos have thumbUrl but it's wrong now,
            // next line needs to be removed when the API starts returning the correct video thumbUrl
            type === 'Image' ? (
              <img
                alt=""
                src={thumbUrl}
              />
            ) : type === 'Image' ? (
              <Icon
                customClass="placeholder"
                name="image"
              />
            ) : (
              <>
                {duration ? <Time>{getTime()}</Time> : ''}
                <Icon
                  customClass="placeholder"
                  name="video"
                />
              </>
            )}
          </>
        ) : (
          <Progress loadingProgress={loadingProgress}>
            <span />
          </Progress>
        )}
      </Image>
      <Actions id={id}>
        <Details isSelected={isSelectedState}>
          <Text
            color={isSelectedState ? 'brandPrimary' : 'brandDark'}
            variation="body_12"
          >
            {filename}
          </Text>
          <Text
            color="brandDarkAlpha70"
            variation="body_12"
          >
            {loadingProgress ? `${uploadingText}...` : updateDate}
          </Text>
        </Details>
        {!loadingProgress && (
          <Button
            aria-controls="menu"
            aria-expanded={currentSelectedDropdownRefId === buttonRefId}
            aria-haspopup="true"
            onBlur={() => setIsTabSelected(false)}
            onClick={() => dropdownButtonClick(options, ref.current)}
            customClass={isTabSelected || isSelected || currentSelectedDropdownRefId === buttonRefId ? 'show-dropdown-button' : ''}
            icon="more_horizontal"
            id={buttonRefId}
            ref={ref}
            size={12}
            data-testid={`${testId}-dropdown-btn`}
          />
        )}
      </Actions>
    </Container>
  );
};
