interface ErrorBoundaryDataInterface {
  mediaLibrarySection: {
    headingText: string;
    buttonText: string;
  };
  contentSection: {
    headingText: string;
    buttonText: string;
  };
  previewAssetsSection: {
    headingText: string;
    buttonText: string;
  };
}

const errorBoundaryData: ErrorBoundaryDataInterface = {
  mediaLibrarySection: {
    headingText: 'media_library_not_load',
    buttonText: 'media_library_try_again',
  },
  contentSection: {
    headingText: 'content_section_not_load',
    buttonText: 'content_section_try_again',
  },
  previewAssetsSection: {
    headingText: 'preview_section_not_load',
    buttonText: 'preview_assets_try_again',
  },
};

export default errorBoundaryData;
