import { throwError } from '@sendible/common';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
import { GetMediaLibrariesReturnType, MediaLibraryMutationParamsType, MediaLibraryType } from './types';
import endpoints from '../../data-layer/endpoints';
import { useQueryWithAccessToken, useMutationWithAccessToken } from '../../data-layer/useReactQueryWithAccessToken';
import { defaultGetMediaLibrariesResponse } from '../../pages/MediaLibrary/constants';
import MediaLibraryContext from '../../pages/MediaLibrary/MediaLibraryContext';
import { mediaLibraryRenamed, mediaLibraryCreated } from '../../pages/MediaLibrary/pendoEvents';
import { QueryKey } from '../../data-layer/types';

const MediaLibrariesQueryKey: QueryKey = [endpoints.GetMediaLibraries.endpoint];

export const useGetMediaLibraries = () =>
  useQueryWithAccessToken<GetMediaLibrariesReturnType>(MediaLibrariesQueryKey, {
    select: useCallback((APIResponse) => {
      if (!APIResponse || !APIResponse.data) {
        throwError('API response was empty when fetching media libraries');
      }

      const mediaLibraries = APIResponse.data;

      return {
        mediaLibraries,
        canDelete: mediaLibraries.length > 1,
      };
    }, []),
    refetchInterval: endpoints.GetMediaLibraries.interval,
  });

export const useDeleteMediaLibrary = () => {
  const queryClient = useQueryClient();
  // const { t } = useTranslation('common_terms');
  const { activeMediaLibrary, setActiveMediaLibrary } = useContext(MediaLibraryContext);
  const { data = defaultGetMediaLibrariesResponse } = useGetMediaLibraries();
  const { mediaLibraries } = data;
  const { method, endpoint } = endpoints.DeleteMediaLibrary;

  return useMutationWithAccessToken<MediaLibraryType, MediaLibraryMutationParamsType>({
    method,
    endpoint,
    options: {
      onSuccess: useCallback(
        (mediaLibrary: MediaLibraryType) => {
          // const message = t('item_deleted', { name: mediaLibrary.name });

          // showToast(message, { type: NotificationTypeEnum.Success });

          queryClient.invalidateQueries(MediaLibrariesQueryKey);
          if (activeMediaLibrary?.id === mediaLibrary.id) setActiveMediaLibrary(mediaLibraries[0]);
        },
        [activeMediaLibrary]
      ),
      onError: useCallback((_, mediaLibrary) => {
        // const message = t('failed_delete', { name: mediaLibrary.name });
        // showToast(message, { type: NotificationTypeEnum.Error });
      }, []),
    },
  });
};

export const useRenameMediaLibrary = (oldName: string) => {
  const queryClient = useQueryClient();
  // const { t } = useTranslation('common_terms');
  const { method, endpoint } = endpoints.RenameMediaLibrary;

  return useMutationWithAccessToken<MediaLibraryType, MediaLibraryMutationParamsType>({
    method,
    endpoint,
    options: {
      onSuccess: useCallback((mediaLibrary: MediaLibraryType) => {
        // const message = t('item_changed', { oldName, newName: mediaLibrary.name });

        // showToast(message, { type: NotificationTypeEnum.Success });
        window.pendo.track(mediaLibraryRenamed, {
          mediaLibraryOldName: oldName,
          mediaLibraryNewName: mediaLibrary.name,
          mediaLibraryId: mediaLibrary.id,
          mediaLibraryWorkspaceId: mediaLibrary.workspaceId,
        });
        queryClient.invalidateQueries(MediaLibrariesQueryKey);
      }, []),
      onError: useCallback(() => {
        // const message = t('failed_changed', { oldName });
        // showToast(message, { type: NotificationTypeEnum.Error });
      }, []),
    },
  });
};

export const useCreateMediaLibrary = () => {
  const queryClient = useQueryClient();
  // const { t } = useTranslation('common_terms');
  const { method, endpoint } = endpoints.CreateMediaLibrary;

  return useMutationWithAccessToken<MediaLibraryType, MediaLibraryMutationParamsType>({
    method,
    endpoint,
    options: {
      onSuccess: useCallback((mediaLibrary: MediaLibraryType) => {
        // const message = t('item_created', { name: mediaLibrary.name });

        // showToast(message, { type: NotificationTypeEnum.Success });
        window.pendo.track(mediaLibraryCreated, {
          mediaLibraryName: mediaLibrary.name,
          mediaLibraryId: mediaLibrary.id,
          mediaLibraryWorkspaceId: mediaLibrary.workspaceId,
        });
        queryClient.invalidateQueries(MediaLibrariesQueryKey);
      }, []),
      onError: useCallback((_, mediaLibrary) => {
        // const message = t('failed_create', { name: mediaLibrary.name });
        // showToast(message, { type: NotificationTypeEnum.Error });
      }, []),
    },
  });
};
