export const youtube_color = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4985 6.21019C23.2225 5.17088 22.4092 4.35236 21.3767 4.07458C19.505 3.56982 12 3.56982 12 3.56982C12 3.56982 4.49503 3.56982 2.62336 4.07458C1.59078 4.3524 0.777524 5.17088 0.501504 6.21019C0 8.09399 0 12.0244 0 12.0244C0 12.0244 0 15.9547 0.501504 17.8385C0.777524 18.8779 1.59078 19.6623 2.62336 19.9401C4.49503 20.4448 12 20.4448 12 20.4448C12 20.4448 19.505 20.4448 21.3767 19.9401C22.4092 19.6623 23.2225 18.8779 23.4985 17.8385C24 15.9547 24 12.0244 24 12.0244C24 12.0244 24 8.09399 23.4985 6.21019Z"
      fill="#FF0000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.54546 15.5929V8.45593L15.8182 12.0245L9.54546 15.5929Z"
      fill="white"
    />
  </>
);
