import styled, { css } from 'styled-components';
import { SidebarNavItemProps } from '.';

interface ContentProps {
  hasOptions?: boolean;
}

export const Container = styled.li<Pick<SidebarNavItemProps, 'isActive'> & { isDropdownVisible: boolean }>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      motions,
      motionSpeeds,
    },
    isActive,
    isDropdownVisible,
  }) => css`
    align-items: center;
    display: flex;
    height: 3rem;
    transition: all ${motionSpeeds.fast} ${motions.default};
    width: 100%;
    box-sizing: border-box;

    ${isActive &&
    css`
      box-shadow: inset -2px 0 0 ${colors.brandPrimary}};

      &:focus-visible {
        border-right: 0;
      }
    `}

    .sidebar-nav-item-button {
      transition: none;
    }

    ${isDropdownVisible &&
    css`
      .sidebar-nav-item-button {
        margin-right: 1rem;
        opacity: 1;
        visibility: visible;
      }

      &:hover {
        .sidebar-nav-item-button {
          margin-right: 1rem;
          opacity: 1;
          visibility: visible;
        }
      }
    `}

    ${!isDropdownVisible &&
    css`
      .sidebar-nav-item-button {
        margin-right: -2rem;
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        .sidebar-nav-item-button {
          margin-right: 1rem;
          opacity: 1;
          visibility: visible;
        }
      }
    `}

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      box-shadow: none;
      height: 3.5rem;

      .sidebar-nav-item-button {
        margin-right: 1rem;
        opacity: 1;
        visibility: visible;
      }

      ${isActive &&
      css`
        background: ${colors.brandPrimaryAlpha5};
      `}
    }
  `}
`;

export const Content = styled.button<Pick<SidebarNavItemProps, 'isActive'> & ContentProps>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      fontSizes,
      fontWeights,
      motions,
      motionSpeeds,
      borderRadius,
    },
    isActive,
  }) => css`
    all: unset;
    align-items: center;
    display: grid;
    flex: 1;
    grid-auto-flow: column;
    height: 100%;
    text-decoration: none;
    transition: all ${motionSpeeds.fast} ${motions.default};
    user-select: none;
    padding: 0 1rem 0 1.5rem;
    justify-content: flex-start;
    gap: 16px;

    .label-item {
      color: ${colors.brandDark};
      font-size: ${fontSizes.s16};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ${isActive &&
    css`
      .label-item {
        color: ${colors.brandPrimary};
        font-weight: ${fontWeights.medium};
      }
    `}

    &:hover {
      .label-item {
        color: ${colors.brandPrimary};
      }
    }

    &:focus-visible {
      border-radius: ${borderRadius.sm};
      box-shadow: inset 0 0 0 2px ${colors.brandPrimary};
    }
    .icon {
      margin-right: 1rem;
    }
    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      ${isActive &&
      css`
        .label-item {
          font-weight: ${fontWeights.regular};
        }
      `}
    }
  `}
`;

export const Avatar = styled.div`
  border-radius: 100rem;
  flex-shrink: 0;
  height: 2rem;
  margin-right: 1rem;
  overflow: hidden;
  width: 2rem;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;
