import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';

const isProduction = process.env.SENDIBLE_ENV === 'production';

const options: InitOptions = {
  debug: false,
  fallbackLng: 'en',
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ...enTranslation,
  },
  saveMissing: !isProduction,
};

i18n.use(initReactI18next).init(options);

export default i18n;
