import { fetchWithHeaders, urls, errorUtils } from '@sendible/common';
import { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import axios from 'axios';
import { AssetUploadedPartType, AssetUploadIntentType, AssetUploadParamsType, AssetUploadPartType } from './types';
import { useBackgroundUploaderContext } from './BackgroundUploaderContext';
import endpoints from '../../data-layer/endpoints';
import { AssetStatus, AssetType } from '../../models/assets/types';
import MediaLibraryContext from '../../pages/MediaLibrary/MediaLibraryContext';
import { useGetAssetsQueryKey } from '../../models/assets';
import { getValidAndInvalidFiles } from '../../models/assets/utils/fileUploadValidation';
import { singleFileUploadSuccess, singleFileUploadFailure, singleFileUploadCancelled } from '../../pages/MediaLibrary/pendoEvents';
import { APIErrorType } from '../../data-layer/types';
import { allValuesForAssetsPerPage } from '../../pages/MediaLibrary/constants';

const completeUpload = async (
  uploadIntent: AssetUploadIntentType,
  parts: AssetUploadedPartType[],
  params: AssetUploadParamsType
): Promise<boolean> => {
  const {
    id,
    upload: { id: uploadId },
  } = uploadIntent;
  const completeUploadParams = {
    access_token: params.access_token,
    id,
    upload: {
      id: uploadId,
      parts,
    },
  };

  const completeUploadResponse: AssetType = await fetchWithHeaders({
    method: endpoints.CompleteUploadAssets.method,
    url: `${urls.baseUrl}${endpoints.CompleteUploadAssets.endpoint}`,
    body: completeUploadParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return completeUploadResponse?.status === AssetStatus.Ready;
};

const abortUpload = async (uploadIntent: AssetUploadIntentType, params: AssetUploadParamsType) => {
  const {
    id,
    upload: { id: uploadId },
  } = uploadIntent;
  const abortUploadParams = {
    access_token: params.access_token,
    id,
    upload: {
      id: uploadId,
    },
  };

  const abortUploadResponse: AssetType = await fetchWithHeaders({
    method: endpoints.AbortUploadAssets.method,
    url: `${urls.baseUrl}${endpoints.AbortUploadAssets.endpoint}`,
    body: abortUploadParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return abortUploadResponse?.status === AssetStatus.Aborted;
};

export const useUploadFiles = () => {
  const { addFile, updateFileProgress, updateFileError, updateFileReady, addAbortAPIToFile } = useBackgroundUploaderContext();
  const { activeMediaLibrary } = useContext(MediaLibraryContext);
  const queryClient = useQueryClient();
  const assetsQueryKey = useGetAssetsQueryKey();
  let abortedIds: string[] = [];

  const isAborted = (fileId: string) => {
    return abortedIds.includes(fileId);
  };

  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const params: AssetUploadParamsType = {
    access_token: accessToken,
    mediaLibraryId: activeMediaLibrary.id,
  };

  const uploadParts = (
    uploadIntent: AssetUploadIntentType,
    file: File,
    fileId: string,
    abortController: AbortController
  ): Promise<AssetUploadedPartType[]> => {
    const {
      upload: { parts, partSize },
    } = uploadIntent;
    const progressTracker: number[] = [];

    return Promise.all(
      parts.map(async (part: AssetUploadPartType): Promise<AssetUploadedPartType> => {
        const { url, partNumber } = part;
        const partStartByte = partSize * (partNumber - 1); // partNumber starts with 1
        const isLastPart = partNumber === parts.length;
        const partEndByte = isLastPart ? undefined : partSize * partNumber;
        const partialBlob = file.slice(partStartByte, partEndByte);

        const uploadedPartResponse = await axios.put(url, partialBlob, {
          headers: { 'content-type': file.type },
          signal: abortController.signal,
          onUploadProgress: (progressEvent) => {
            if (progressEvent?.total) {
              progressTracker[partNumber - 1] = Math.round((progressEvent.loaded * 100) / progressEvent.total);

              const totalProgress = progressTracker.reduce((sum, progress) => sum + progress, 0) / parts.length;

              if (!isAborted(fileId)) updateFileProgress({ fileId, uploadProgress: totalProgress, abortController });
            }
          },
        });

        if (uploadedPartResponse && uploadedPartResponse.status === 200 && uploadedPartResponse?.headers) {
          const eTag = uploadedPartResponse.headers.etag;

          if (eTag) {
            return {
              eTag: eTag.replaceAll('"', ''),
              partNumber,
            };
          }
        }

        throw new Error('Failed to upload part');
      })
    );
  };

  const uploadFile = async (file: File, fileId: string, abortController: AbortController) => {
    const { method, endpoint } = endpoints.UploadAssets;

    const parts = await fetchWithHeaders({
      method,
      url: `${urls.baseUrl}${endpoint}`,
      params: { ...params, name: file.name, size: file.size, mediaType: file.type },
    });

    addAbortAPIToFile(fileId, () => {
      abortUpload(parts, params);
      abortedIds = [...abortedIds, fileId];
    });

    try {
      const uploadedParts = await uploadParts(parts, file, fileId, abortController);

      if (!isAborted(fileId)) {
        return completeUpload(parts, uploadedParts, params);
      }
    } catch (error) {
      if (!isAborted(fileId)) {
        abortUpload(parts, params);
        throw error;
      }
    }
  };

  const uploadFiles = async (files: File[]) => {
    const { validFiles, invalidFiles } = getValidAndInvalidFiles(files);

    invalidFiles.forEach((file) => {
      const fileId = addFile(file, activeMediaLibrary.name, new AbortController());

      updateFileError({
        fileId,
        errorMessage: 'uploader_items_invalid',
      });
    });

    if (validFiles.length) {
      files.forEach(async (file) => {
        const filePropertiesForPendo = {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        };

        const abortController = new AbortController();

        const fileId = addFile(file, activeMediaLibrary.name, abortController);

        try {
          const res = await uploadFile(file, fileId, abortController);

          if (res) {
            allValuesForAssetsPerPage.forEach((assetsPerPage) => {
              assetsQueryKey.map((value) => {
                if (typeof value !== 'object' || typeof value.perPage === 'undefined') return value;

                return { ...value, perPage: assetsPerPage };
              });
              queryClient.invalidateQueries();
            });

            updateFileReady(fileId);
            window.pendo.track(singleFileUploadSuccess, filePropertiesForPendo);
          } else {
            window.pendo.track(singleFileUploadCancelled, filePropertiesForPendo);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          let errorMessage;

          if (error.response?.data?.error) {
            const { message, type } = error.response.data.error as APIErrorType;

            errorMessage = `${type}: ${message}`;
          } else if (error.message) {
            errorMessage = error.message;
          } else {
            errorMessage = error;
          }

          updateFileError({ fileId, errorMessage });
          errorUtils.sendErrorDirectlyToOnerror(error as string);
          window.pendo.track(singleFileUploadFailure, filePropertiesForPendo);
        }
      });
    }
  };

  return {
    uploadFiles,
  };
};
