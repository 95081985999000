import { useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { fetchWithHeaders, urls } from '@sendible/common';
import { AssetType, AssetsAPIResponseType, AssetMutationParamsType } from '@sendible/frontendv2/src/models/assets/types';
import { InfiniteQueryKey } from '../../data-layer/types';
import endpoints from '../../data-layer/endpoints';
import mediaLibraryContext from '../../pages/MediaLibrary/MediaLibraryContext';
import { useInfiniteQueryWithAccessToken, useMutationWithAccessToken } from '../../data-layer/useReactQueryWithAccessToken';
import { assetRenameFromPreview } from '../../pages/MediaLibrary/pendoEvents';
import SelectedAssetsContext from '../../pages/MediaLibrary/SelectedAssetsContext';
// import { NotificationTypeEnum, showToast } from '../../components/ToastNotification';

export const useGetAssetsQueryKey = (): InfiniteQueryKey => {
  const { activeMediaLibrary, assetSearch, perPage } = useContext(mediaLibraryContext);
  const paramObj = {
    mediaLibraryId: activeMediaLibrary?.id,
    'filter[name]': assetSearch,
    perPage,
  };

  return [endpoints.GetAssets.endpoint, paramObj];
};

export const useInfiniteGetAssets = () => {
  const { activeMediaLibrary } = useContext(mediaLibraryContext);
  const assetsQueryKey = useGetAssetsQueryKey();

  return useInfiniteQueryWithAccessToken<AssetsAPIResponseType>(assetsQueryKey, {
    enabled: !!activeMediaLibrary?.id,
    staleTime: endpoints.GetAssets.interval,
    refetchInterval: endpoints.GetAssets.interval,
  });
};

export const useDeleteAsset = (pendoEvent: string, mediaType?: string) => {
  const queryClient = useQueryClient();
  const assetsQueryKey = useGetAssetsQueryKey();
  const { selectedAssetsIds, setSelectedAssetsIds } = useContext(SelectedAssetsContext);
  // const { t } = useTranslation('media_library');
  const { method, endpoint } = endpoints.DeleteAsset;

  return useMutationWithAccessToken<AssetType, AssetMutationParamsType>({
    method,
    endpoint,
    options: {
      onSuccess: useCallback(
        ({ id, name }) => {
          // showToast(t('toast_single_asset_deletion_success', { assetName: name }) || '', { type: NotificationTypeEnum.Success });
          window.pendo.track(pendoEvent, {
            name,
            type: mediaType,
          });
          queryClient.invalidateQueries(assetsQueryKey);

          // If the asset is selected, deselect it
          if (selectedAssetsIds.includes(id)) {
            const newList = [...selectedAssetsIds];

            newList.splice(newList.indexOf(id), 1);
            setSelectedAssetsIds(newList);
          }
        },
        [selectedAssetsIds]
      ),
      onError: useCallback((_, { name }) => {
        // showToast(t('toast_assets_deletion_error', { name }) || '', { type: NotificationTypeEnum.Error });
      }, []),
    },
  });
};

export const useDeleteMediaLibraryMultipleAssets = (pendoEvent: string) => {
  const queryClient = useQueryClient();
  const assetsQueryKey = useGetAssetsQueryKey();
  // const { t } = useTranslation('media_library');

  const { method, endpoint } = endpoints.DeleteAsset;

  return useMutationWithAccessToken<AssetType[], { selectedAssetsIds: string[]; access_token: string }>({
    method,
    endpoint,
    options: {
      onSuccess: (selectedAssetsIds) => {
        queryClient.invalidateQueries(assetsQueryKey);

        // showToast(t('toast_multiple_assets_deletion_success', { numberOfAssets: selectedAssetsIds.length }) || '', {
        // type: NotificationTypeEnum.Success,
        // });

        window.pendo.track(pendoEvent, { selectedAssetsIds });
      },
      onError: () => {
        // showToast(t('toast_multiple_assets_deletion_error') || '', {
        //   type: NotificationTypeEnum.Error,
        // });
      },
    },
    mutationFn: async ({ selectedAssetsIds, access_token }) => {
      const responses: PromiseSettledResult<AssetType>[] = await Promise.allSettled(
        selectedAssetsIds.map((assetId) =>
          fetchWithHeaders({
            method,
            url: `${urls.baseUrl}${endpoint}`,
            params: { id: assetId, access_token },
          })
        )
      );

      const rejectedResponses = responses.filter((response) => response.status === 'rejected');

      if (rejectedResponses.length > 0) {
        throw new Error();
      }

      return responses.filter((response) => response.status === 'fulfilled').map((response) => (response as PromiseFulfilledResult<AssetType>).value);
    },
  });
};

export const useRenameAsset = (mediaType: string) => {
  // const { t } = useTranslation('media_library');
  const queryClient = useQueryClient();
  const assetsQueryKey = useGetAssetsQueryKey();

  return useMutationWithAccessToken<AssetType, AssetMutationParamsType>({
    method: endpoints.RenameAsset.method,
    endpoint: endpoints.RenameAsset.endpoint,
    options: {
      onSuccess: useCallback(({ name }) => {
        // showToast(t('toast_asset_rename_success') || '', { type: NotificationTypeEnum.Success });

        window.pendo.track(assetRenameFromPreview, {
          name,
          type: mediaType,
        });
        queryClient.invalidateQueries(assetsQueryKey);
      }, []),
      onError: useCallback(() => {
        // showToast(t('toast_asset_rename_error') || '', { type: NotificationTypeEnum.Error });
      }, []),
    },
  });
};
