import { createContext, useContext, useState } from 'react';
import { ToastNotificationProps, ToastPositions } from '../../molecules/Toast';

export type ToastNotificationData = Partial<Omit<ToastNotificationProps, 'close' | 'index'>> & {
  hideTimeout?: null | number;
  position?: ToastPositions;
  id?: number;
  parentElement?: HTMLElement;
} & Component;

interface ToastNotificationProviderData {
  toastNotificationState: ToastNotificationData[];
  showToastNotification: (payload: ToastNotificationData) => void;
  closeToastNotification: (index?: number) => void;
}

export const ToastNotificationContext = createContext({} as ToastNotificationProviderData);

export const ToastNotificationProvider = ({ children }: Component) => {
  const [toastNotificationState, setToastNotificationState] = useState<ToastNotificationData[]>([]);

  const showToastNotification = (payload: ToastNotificationProps) => {
    setToastNotificationState([
      ...toastNotificationState,
      {
        ...payload,
        hideTimeout: payload.type === 'success' ? 10 : payload.hideTimeout,
        id: Date.now(),
        position: payload.position || 'top',
      } as ToastNotificationData,
    ]);
  };

  const closeToastNotification = (id?: number) => {
    setToastNotificationState((state) => {
      const newToastNotificationState = [...state];
      const index = newToastNotificationState.findIndex((toast) => toast.id === id);

      newToastNotificationState.splice(index, 1);

      return newToastNotificationState;
    });
  };

  return (
    <ToastNotificationContext.Provider
      value={{
        toastNotificationState,
        showToastNotification,
        closeToastNotification,
      }}
    >
      {children}
    </ToastNotificationContext.Provider>
  );
};

export const useToastNotificationContext = (): ToastNotificationProviderData => {
  const context = useContext(ToastNotificationContext);

  if (!context) {
    throw new Error('useToastNotificationContext must be used within a ToastNotificationProvider');
  }

  return context;
};
