import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@sendible/design-system';
import { MediaLibraryType } from '../../../../models/mediaLibraries/types';
import { useDeleteMediaLibrary } from '../../../../models/mediaLibraries';

interface DeletionModalContentProps {
  mediaLibrary: MediaLibraryType;
}

export const DeleteMediaLibraryModalContent: FC<DeletionModalContentProps> = ({ mediaLibrary }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteMediaLibraryMutation = useDeleteMediaLibrary();
  const { t } = useTranslation('media_library');

  const closeButtonLabel = t('deletion_modal_button_back');
  const deleteButtonLabel = t('dropdown_delete');
  const ariaCloseDeleteModalLabel = t('aria_close_delete_modal');
  const ariaDeleteAssetLabel = t('aria_delete_asset');

  const handleMediaLibraryDeletion = () => {
    setIsDeleting(true);

    deleteMediaLibraryMutation.mutate(
      { id: mediaLibrary.id, name: mediaLibrary.name },
      {
        onSettled: () => {
          setIsDeleting(false);
        },
      }
    );
  };

  return (
    <Box>
      <Text>{t('deletion_modal_text', { mediaName: mediaLibrary.name })}</Text>
      <Box
        contentAlignment="stretch"
        orientation="column"
        verticalGap="s8"
        flex={1}
        margin={['s24', 's0', 's0', 's0']}
      >
        <Button
          // onClick={() => closeModal()}
          label={closeButtonLabel}
          size={16}
          data-testid="modal-button-back"
          variation="outline"
          autoFocus
          aria-label={ariaCloseDeleteModalLabel}
        />
        <Button
          appearance="primary"
          onClick={() => handleMediaLibraryDeletion()}
          label={deleteButtonLabel}
          isLoading={isDeleting}
          size={16}
          data-testid="modal-button-delete"
          variation="fill"
          aria-label={ariaDeleteAssetLabel}
        />
      </Box>
    </Box>
  );
};
